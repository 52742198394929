<template>
  <div class="project-left-bottom">
    <div class="tabs">
      <button :class="['tab', bottomTabIndex === 0 ? 'current' : '']" @click="onTabClick(0)">
        <img class="icon" :src="barIcon" />
        <span class="text">投资人主要指标</span>
      </button>
      <div class="tab-separator" />
      <button :class="['tab', bottomTabIndex === 1 ? 'current' : '']" @click="onTabClick(1)" v-if="version1 && version2 && Object.keys(axMainIndexData).length > 0">
        <img class="icon" :src="barIcon" />
        <span class="text">安星主要指标</span>
      </button>
    </div>
    <div class="separator" />
    <swiper class="body" ref="swiper" @slideChange="onChartChange">
      <swiper-slide class="item">
        <div class="table" v-if="version1 && version2 && Object.keys(investorMainIndexData).length > 0">
          <div class="row title">
            <div class="cell">
              <span class="text">核心指标</span>
            </div>
            <div class="separator-vertical" />
            <div class="cell">
              <span class="text">{{version1.name}}</span>
            </div>
            <div class="separator-vertical" />
            <div class="cell">
              <span class="text">{{version2.name}}</span>
            </div>
            <div class="separator-vertical" />
            <div class="cell">
              <span class="text">B-A</span>
            </div>
          </div>
          <div class="row">
            <div class="cell">
              <span class="text">本金金额</span>
            </div>
            <div class="cell">
              <span class="text">{{investorMainIndexData.version1.principalBalance}}万元</span>
            </div>
            <div class="cell">
              <span class="text">{{investorMainIndexData.version2.principalBalance}}万元</span>
            </div>
            <div class="cell" :class="getClass(investorMainIndexData, 'principalBalance')">
              <span class="text">{{investorMainIndexData.diff.principalBalance}}</span>
            </div>
          </div>
          <div class="row odd">
            <div class="cell">
              <span class="text">投资收益</span>
            </div>
            <div class="cell">
              <span class="text">{{investorMainIndexData.version1.investIncome}}万元</span>
            </div>
            <div class="cell">
              <span class="text">{{investorMainIndexData.version2.investIncome}}万元</span>
            </div>
            <div class="cell" :class="getClass(investorMainIndexData, 'investIncome')">
              <span class="text">{{investorMainIndexData.diff.investIncome}}</span>
            </div>
          </div>
          <div class="row">
            <div class="cell">
              <span class="text">IRR</span>
            </div>
            <div class="cell">
              <span class="text">{{ formatIrr(investorMainIndexData.version1.irrTotal) }}</span>
            </div>
            <div class="cell">
              <span class="text">{{ formatIrr(investorMainIndexData.version2.irrTotal) }}</span>
            </div>
            <div class="cell" :class="getClass(investorMainIndexData, 'irrTotal')">
              <span class="text">{{ formatPercentage(investorMainIndexData.diff.irrTotal) }}</span>
            </div>
          </div>
          <div class="row odd">
            <div class="cell">
              <span class="text">投资峰值</span>
            </div>
            <div class="cell">
              <span class="text">{{investorMainIndexData.version1.investPeak}}万元</span>
            </div>
            <div class="cell">
              <span class="text">{{investorMainIndexData.version2.investPeak}}万元</span>
            </div>
            <div class="cell" :class="getClass(investorMainIndexData, 'investPeak')">
              <span class="text">{{investorMainIndexData.diff.investPeak}}</span>
            </div>
          </div>
          <div class="row">
            <div class="cell">
              <span class="text">回正周期</span>
            </div>
            <div class="cell">
              <span class="text">{{investorMainIndexData.version1.recoveryCycle}}个月</span>
            </div>
            <div class="cell">
              <span class="text">{{investorMainIndexData.version2.recoveryCycle}}个月</span>
            </div>
            <div class="cell" :class="getClass(investorMainIndexData, 'recoveryCycle')">
              <span class="text">{{investorMainIndexData.diff.recoveryCycle}}个月</span>
            </div>
          </div>
          <div class="row odd">
            <div class="cell">
              <span class="text">回正时间</span>
            </div>
            <div class="cell">
              <span class="text">{{investorMainIndexData.version1.recoveryDate}}</span>
            </div>
            <div class="cell">
              <span class="text">{{investorMainIndexData.version2.recoveryDate}}</span>
            </div>
            <div class="cell" :class="getClass(investorMainIndexData, 'recoveryDate')">
              <span class="text">{{investorMainIndexData.diff.recoveryDate}}</span>
            </div>
          </div>
        </div>
        <div class="body" v-else>
          <empty />
        </div>
      </swiper-slide>
      <swiper-slide class="item">
        <div class="table" v-if="version1 && version2 && Object.keys(axMainIndexData).length > 0">
          <div class="row title">
            <div class="cell">
              <span class="text">核心指标</span>
            </div>
            <div class="separator-vertical" />
            <div class="cell">
              <span class="text">{{version1.name}}</span>
            </div>
            <div class="separator-vertical" />
            <div class="cell">
              <span class="text">{{version2.name}}</span>
            </div>
            <div class="separator-vertical" />
            <div class="cell">
              <span class="text">B-A</span>
            </div>
          </div>
          <div class="row">
            <div class="cell">
              <span class="text">本金金额</span>
            </div>
            <div class="cell">
              <span class="text">{{axMainIndexData.version1.principalBalance}}万元</span>
            </div>
            <div class="cell">
              <span class="text">{{axMainIndexData.version2.principalBalance}}万元</span>
            </div>
            <div class="cell" :class="getClass(axMainIndexData, 'principalBalance')">
              <span class="text">{{axMainIndexData.diff.principalBalance}}</span>
            </div>
          </div>
          <div class="row odd">
            <div class="cell">
              <span class="text">投资收益</span>
            </div>
            <div class="cell">
              <span class="text">{{axMainIndexData.version1.investIncome}}万元</span>
            </div>
            <div class="cell">
              <span class="text">{{axMainIndexData.version2.investIncome}}万元</span>
            </div>
            <div class="cell" :class="getClass(axMainIndexData, 'investIncome')">
              <span class="text">{{axMainIndexData.diff.investIncome}}</span>
            </div>
          </div>
          <div class="row">
            <div class="cell">
              <span class="text">IRR</span>
            </div>
            <div class="cell">
              <span class="text">{{ formatIrr(axMainIndexData.version1.irrTotal) }}</span>
            </div>
            <div class="cell">
              <span class="text">{{ formatIrr(axMainIndexData.version2.irrTotal) }}</span>
            </div>
            <div class="cell" :class="getClass(axMainIndexData, 'irrTotal')">
              <span class="text">{{ formatPercentage(axMainIndexData.diff.irrTotal) }}</span>
            </div>
          </div>
          <div class="row odd">
            <div class="cell">
              <span class="text">投资峰值</span>
            </div>
            <div class="cell">
              <span class="text">{{axMainIndexData.version1.investPeak}}万元</span>
            </div>
            <div class="cell">
              <span class="text">{{axMainIndexData.version2.investPeak}}万元</span>
            </div>
            <div class="cell" :class="getClass(axMainIndexData, 'investPeak')">
              <span class="text">{{axMainIndexData.diff.investPeak}}</span>
            </div>
          </div>
          <div class="row">
            <div class="cell">
              <span class="text">回正周期</span>
            </div>
            <div class="cell">
              <span class="text">{{axMainIndexData.version1.recoveryCycle}}个月</span>
            </div>
            <div class="cell">
              <span class="text">{{axMainIndexData.version2.recoveryCycle}}个月</span>
            </div>
            <div class="cell" :class="getClass(axMainIndexData, 'recoveryCycle')">
              <span class="text">{{axMainIndexData.diff.recoveryCycle}}个月</span>
            </div>
          </div>
          <div class="row odd">
            <div class="cell">
              <span class="text">回正时间</span>
            </div>
            <div class="cell">
              <span class="text">{{axMainIndexData.version1.recoveryDate}}</span>
            </div>
            <div class="cell">
              <span class="text">{{axMainIndexData.version2.recoveryDate}}</span>
            </div>
            <div class="cell" :class="getClass(axMainIndexData, 'recoveryDate')">
              <span class="text">{{axMainIndexData.diff.recoveryDate}}</span>
            </div>
          </div>
        </div>
        <div class="body" v-else>
          <empty />
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { ref, computed, watch, onMounted } from "vue";
import { useStore } from "vuex";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import Empty from "@/components/common/Empty";
import project from "@/compositions/project";

export default {
  name: "ProjectLeftMiddle",
  components: {
    Swiper,
    SwiperSlide,
    Empty
  },
  setup() {
    const store = useStore();
    const barIcon = computed(() => store.getters["theme/barIcon"]);

    const data = project.init();
    const { version1, version2, investorMainIndexData, axMainIndexData, bottomTabIndex } = data;
    const swiper = ref(null);

    onMounted(() => {
      watch(() => bottomTabIndex.value, (value) => swiper.value.$el.swiper.slideTo(value));
    });

    const getClass = (data, field) => {
      const value = data.diff[field];
      return { red: value < 0, green: value > 0 };
    };

    const onTabClick = (value) => {
      console.log(value);
      bottomTabIndex.value = value;
    };

    const onChartChange = (event) => {
      console.log(event)
      bottomTabIndex.value = event.realIndex;
    };

    const formatIrr = (irr) => {
      return irr > 0 ? (irr * 100).toFixed(2) + '%' : '负值'
    };

    const formatPercentage = (p) => {
      return (p * 100).toFixed(2) + '%'
    };

    return {
      barIcon,
      version1,
      version2,
      investorMainIndexData,
      axMainIndexData,
      bottomTabIndex,
      swiper,
      getClass,
      onTabClick,
      onChartChange,
      formatIrr,
      formatPercentage
    };
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/main.scss";

.project-left-bottom {
  display: flex;
  flex-direction: column;
  width: 456px;
  height: 227px;
}

.tabs {
  @extend %horizontal;
  width: 100%;
  height: 36px;

  .tab {
    @extend %center;
    flex: 1;
    height: 36px;
    border-bottom: 1px solid var(--secondary-border-color);

    .icon {
      margin-right: 8px;
      width: 18px;
      height: 18px;
    }

    .text {
      font-size: 14px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: var(--tip-font-color);
    }

    &.current {
      background: linear-gradient(360deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
      border-bottom: 1px solid var(--main-font-color);

      .text {
        color: var(--main-font-color);
      }
    }
  }

  .tab-separator {
    width: 1px;
    height: 100%;
    background-color: var(--secondary-border-color);
  }
}

.item {
  display: flex;
  flex-direction: column;
  width: 456px;
  height: 189px;
}

.body {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;

  .table {
    display: flex;
    flex-direction: column;
    width: 100%;

    .row {
      @extend %horizontal;
      width: 100%;
      height: 27.14px;

      .cell {
        @extend %horizontal;
        flex: 1;
        height: 100%;

        .text {
          margin-left: 12px;
          font-size: 14px;
          font-family: MicrosoftYaHei;
          color: var(--main-font-color);
        }

        &.red {
          .text {
            color: var(--danger-color);
          }
        }

        &.green {
          .text {
            color: var(--success-color);
          }
        }
      }

      &.title {
        background-color: var(--secondary-background-color);

        .text {
          color: var(--tip-font-color);
        }
      }

      &.odd {
        background-color: var(--thirdary-background-color);
      }

      .separator-vertical {
        width: 1px;
        height: 19px;
        background-color: var(--secondary-border-color);
      }
    }
  }
}
</style>
