<template>
  <div class="prject-header">
    <div class="separator" />
    <div class="left">
      <logo class="logo" :title="currentProject ? currentProject.name : ''" />
      <el-cascader 
        :style="{marginLeft: '16px', width: '120px'}" 
        ref="projectSelector"
        :options="productTree" 
        :props="{value: 'id', label: 'name', children: 'children', expandTrigger: 'hover', checkStrictly: true}"
        v-model="selectProjectId"
        @change="onProjectChange"
      />
      <div :class="{status: true, ...getStatusClass()}" v-if="Object.keys(projectIntroduction).length > 0">
        <div class="bg" />
        <div class="status-left">
          <img class="icon" :src="alertIcon" />
          <span class="text">项目预警</span>
        </div>
        <span class="status-text">{{getStatusText()}}</span>
      </div>
    </div>
    <div class="right">
      <el-date-picker 
        :style="{width: '120px'}" 
        type="date" 
        value-format="YYYY-MM-DD" 
        placeholder="选择日期" 
        :clearable="false" 
        v-model="date1" 
      />
      <el-select class="selector" placeholder="选择版本" v-model="versionId1">
        <el-option :label="version.name" :value="version.id" v-for="version in versionList1" :key="version.id" />
      </el-select>
      <span class="text">VS</span>
      <el-select class="selector" placeholder="选择版本" v-model="versionId2">
        <el-option :label="version.name" :value="version.id" v-for="version in versionList2" :key="version.id" />
      </el-select>
      <el-date-picker 
        :style="{marginLeft: '8px', width: '120px'}" 
        type="date" 
        value-format="YYYY-MM-DD" 
        placeholder="选择日期" 
        :clearable="false" 
        v-model="date2" 
      />
    </div>
    <commands />
	</div>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import project from "@/compositions/project";
import Logo from "@/components/common/Logo";
import Commands from "@/components/common/Commands";

export default {
  name: "ProductHeader",
  components: {
    Logo,
    Commands
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const alertIcon = computed(() => store.getters["theme/alertIcon"]);
    const changeThemeIcon = computed(() => store.getters["theme/changeThemeIcon"]);
    const projectSelector = ref(null);

    const data = project.init();
    const { 
      selectProjectId, 
      projectId, 
      productTree, 
      projectIntroduction,
      currentProject, 
      versionList1, 
      versionList2, 
      date1, 
      versionId1, 
      date2, 
      versionId2 
    } = data;

    const onProjectChange = (data) => {
      if (data[1]) {
        // projectId.value = Number(data[1].split("-")[1]);
        // projectSelector.value.popperVisible = false;
        const id = Number(data[1].split("-")[1]);
        router.replace({
          path: "/project", 
          query: {
            id: id
          }
        });
        setTimeout(() => router.go(0), 0);
      } else {
        const value = data[0];
        if (value === 0) {
          router.replace("/general");
        } else {
          const productId = Number(data[0].split("-")[1]);
          router.push({
            path: "/product", 
            query: { 
              id: productId
            }
          });
        }
      }
    };

    const getStatusClass = () => {
      if (projectIntroduction.value.warningStatus === 1) {
        return { warn: true };
      } else if (projectIntroduction.value.warningStatus === 2) {
        return { danger: true };
      } else {
        return {};
      }
    };

    const getStatusText = () => {
      if (projectIntroduction.value.warningStatus === 1) {
        return "预警";
      } else if (projectIntroduction.value.warningStatus === 2) {
        return "出险";
      } else {
        return "正常";
      }
    };

    return {
      alertIcon,
      changeThemeIcon,
      projectSelector,
      selectProjectId,
      projectId,
      productTree,
      projectIntroduction,
      currentProject,
      versionList1,
      versionList2,
      date1,
      versionId1,
      date2,
      versionId2,
      onProjectChange,
      getStatusClass,
      getStatusText
    };
  }
}
</script>

<style lang="scss">
.popper {
  background-color: #333333 !important;
  border: none !important;

  .el-cascader-node__label {
    color: #999999;
  }

  .el-radio__inner {
    background-color: #333333 !important;
    border: 1px solid #999999;
  }

  .el-radio__input.is-checked .el-radio__inner {
    background-color: #FF8517 !important;
    border: none;
  }

  .el-cascader-menu {
    border: 1px solid rgba(255, 255, 255, 0.1);
  }

  .el-cascader-node.is-active {
    color: #FF8517;
  }

  .el-popper__arrow::before {
    background-color: #333333 !important;
    border-color: #333333 !important;
  }
}
</style>

<style lang="scss" scoped>
@import "@/styles/main.scss";

.prject-header {
  position: relative;
  @extend %horizontal;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  background-color: var(--main-background-color);

  .separator {
    position: absolute;
    top: 71px;
    left: 16px;
    width: 1888px;
    height: 1px;
    background-color: var(--main-border-color);
  }

  .left {
    @extend %horizontal;

    .logo {
      margin-left: 32px;
    }

    .selector {
      margin-left: 16px;
      width: 120px;
    }

    .status {
      position: relative;
      @extend %horizontal;
      justify-content: space-between;
      margin-left: 12px;
      width: 150px;
      height: 36px;

      .bg {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: var(--success-color);
        opacity: 0.2;
      }

      .status-left {
        @extend %horizontal;
        margin-left: 12px;

        .icon {
          width: 18px;
          height: 18px;
        }

        .text {
          margin-left: 4px;
          font-size: 14px;
          font-family: MicrosoftYaHei;
          color: var(--main-font-color);
        }
      }

      .status-text {
        margin-right: 16px;
        font-size: 14px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: var(--success-color);
      }

      &.warn {
        .bg {
          background-color: var(--warn-color);
        }

        .status-text {
          color: var(--warn-color);
        }
      }

      &.danger {
        .bg {
          background-color: var(--danger-color);
        }

        .status-text {
          color: var(--danger-color);
        }
      }
    }
  }

  .right {
    @extend %horizontal;
    margin-right: 64px;

    .selector {
      margin-left: 8px;
      width: 120px;
    }

    .text {
      margin-left: 15px;
      margin-right: 7px;
      font-size: 16px;
      font-family: MicrosoftYaHei;
      color: var(--main-font-color);
    }
  }
}
</style>
