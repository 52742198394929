<template>
  <div class="project-left">
    <div class="section top">
      <title-bar :icon="projectTargetIcon" title="项目与主要指标">
        <template v-slot:right>
          <button class="btn-detail" @click="onProjectDocumentClick">
            <span class="text">项目文档</span>
            <img class="icon" :src="playIcon" />
          </button>
        </template>
      </title-bar>
      <div class="separator" />
      <div class="body">
        <project-table />
      </div>
    </div>
    <div class="section middle">
      <project-left-middle />
    </div>
    <div class="section bottom">
      <project-left-bottom />
    </div>
	</div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import TitleBar from "@/components/common/TitleBar";
import ProjectTable from "@/components/project/ProjectTable";
import ProjectLeftMiddle from "@/components/project/ProjectLeftMiddle";
import ProjectLeftBottom from "@/components/project/ProjectLeftBottom";

export default {
  name: "ProjectLeft",
  components: {
    TitleBar,
    ProjectTable,
    ProjectLeftMiddle,
    ProjectLeftBottom
  },
  setup() {
    const store = useStore();
    const projectTargetIcon = computed(() => store.getters["theme/projectTargetIcon"]);
    const playIcon = computed(() => store.getters["theme/playIcon"]);
    const barIcon = computed(() => store.getters["theme/barIcon"]);

    const onProjectDocumentClick = () => {

    };

    return {
      projectTargetIcon,
      playIcon,
      barIcon,
      onProjectDocumentClick
    };
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/main.scss";

.project-left {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.section {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  margin-left: 16px;
  width: 456px;
  background-color: var(--main-background-color);

  &.top {
    height: 484px;
  }

  &.middle {
    height: 234px;
  }

  &.bottom {
    margin-bottom: 0;
    height: 226px;
  }

  .body {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
  }

  .btn-detail {
    @extend %center;
    width: 84px;
    height: 24px;
    background-color: var(--secondary-background-color);

    .text {
      font-size: 12px;
      font-family: MicrosoftYaHeiSemibold;
      color: var(--main-font-color);
    }

    .icon {
      margin-left: 4px;
      width: 16px;
      height: 16px;
    }
  }
}
</style>
