<template>
  <div class="top">
    <div class="datas">
      <div class="item blue">
        <div class="line" />
        <template v-if="Object.keys(mainIndexData).length > 0">
          <span class="title">计容可售去化率</span>
          <div class="value">
            <span class="num">{{ mainIndexData.keyIndex.volumnSaleDesalinationRate }}</span>
            <span class="unit">%</span>
          </div>
        </template>
        <empty v-else />
      </div>
      <div class="item orange">
        <div class="line" />
        <template v-if="Object.keys(mainIndexData).length > 0">
          <span class="title">整盘回款率</span>
          <div class="value">
            <span class="num">{{ mainIndexData.keyIndex.repaymentRate }}</span>
            <span class="unit">%</span>
          </div>
        </template>
        <empty v-else />
      </div>
      <div class="item green">
        <div class="line" />
        <template v-if="Object.keys(mainIndexData).length > 0">
          <span class="title">工程付款率</span>
          <div class="value">
            <span class="num">{{ mainIndexData.keyIndex.paymentRate }}</span>
            <span class="unit">%</span>
          </div>
        </template>
        <empty v-else />
      </div>
    </div>
    <swiper class="pictures" :modules="modules" :autoplay="{delay: 3000, disableOnInteraction: false}" :slides-per-view="2.9375" :space-between="12" :slides-offset-before="16" :slides-offset-after="16">
      <swiper-slide class="item" v-for="(picture, index) in projectIntroduction.pictures || []" :key="index">
        <img class="picture" :src="picture" />
      </swiper-slide>
      <template v-if="(projectIntroduction.pictures || []).length < 3">
        <swiper-slide class="item" v-for="index in 3 - (projectIntroduction.pictures || []).length" :key="`placeholder-${index}`">
          <empty class="picture" />
        </swiper-slide>
      </template>
    </swiper>
  </div>
  <div class="notice" v-if="announcementList.length > 0">
    <img class="icon" src="/images/notice.svg" />
    <marquee class="content" :pauseOnHover="true">
      <div class="item" v-for="(announcement, index) in announcementList" :key="index">
        <span class="text">【{{announcement.createTime}} {{announcement.projectName}}】{{announcement.content}}</span>
      </div>
    </marquee>
  </div>
  <div class="bottom">
    <div class="bottom-top" v-if="Object.keys(projectIntroduction).length > 0">
      <div class="line">
        <span class="text">项目公司名称：{{projectIntroduction.companyName}}</span>
      </div>
      <div class="line">
        <span class="text">操盘方：{{projectIntroduction.trader}}</span>
      </div>
      <div class="line">
        <span class="text">土地获取方式：{{projectIntroduction.seekLandMode}}</span>
      </div>
      <div class="line">
        <span class="text">投后人员：{{projectIntroduction.investPostCastPerson}}</span>
      </div>
      <div class="line">
        <span class="text">项目负责人：{{projectIntroduction.principal}}</span>
      </div>
    </div>
    <div class="bottom-top" v-else>
      <empty />
    </div>
    <div class="bottom-middle">
      <div class="tabs">
        <button :class="['tab', topTabIndex === 0 ? 'current' : '']" @click="onTopTabClick(0)">
          <img class="icon" :src="manageIcon" />
          <span class="text">经营累计现金流</span>
        </button>
        <div class="tab-separator" />
        <button :class="['tab', topTabIndex === 1 ? 'current' : '']" @click="onTopTabClick(1)">
          <img class="icon" :src="financingIcon" />
          <span class="text">含融资累计现金流</span>
        </button>
      </div>
      <div class="separator" />
      <swiper class="swiper" ref="topSwiper" @slideChange="onTopChartChange">
        <swiper-slide class="item">
          <div class="body" id="manage-chart" v-if="Object.keys(operateCashFlowTotal).length > 0" />
          <div class="body" v-else>
            <empty />
          </div>
        </swiper-slide>
        <swiper-slide class="item">
          <div class="body" id="finance-chart" v-if="Object.keys(financeCashFlowTotal).length > 0" />
          <div class="body" v-else>
            <empty />
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div class="bottom-bottom">
      <div class="tabs">
        <button :class="['tab', bottomTabIndex === 0 ? 'current' : '']" @click="onBottomTabClick(0)">
          <img class="icon" :src="investorsIcon" />
          <span class="text">投资人累计现金流</span>
        </button>
        <div class="tab-separator" />
        <button :class="['tab', bottomTabIndex === 1 ? 'current' : '']" @click="onBottomTabClick(1)" v-if="Object.keys(axCashFlowTotal).length > 0">
          <img class="icon" :src="starIcon" />
          <span class="text">安星累计现金流</span>
        </button>
      </div>
      <div class="separator" />
      <swiper class="swiper down" ref="bottomSwiper" @slideChange="onBottomChartChange">
        <swiper-slide class="item">
          <div class="body" id="investor-chart" v-if="Object.keys(investorCashFlowTotal).length > 0" />
          <div class="body" v-else>
            <empty />
          </div>
        </swiper-slide>
        <swiper-slide class="item">
          <div class="body" id="anxing-chart" v-if="Object.keys(axCashFlowTotal).length > 0" />
          <div class="body" v-else>
            <empty />
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch, watchEffect, onMounted, onBeforeUnmount, nextTick } from "vue";
import { useStore } from "vuex";
import * as echarts from "echarts";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import Marquee from "vue3-marquee";
import Empty from "@/components/common/Empty";
import project from "@/compositions/project";
import projectCharts from "@/charts/projectCharts";

export default {
  name: "ProjectMiddle",
  components: {
    Swiper,
    SwiperSlide,
    Marquee,
    Empty
  },
  setup() {
    const store = useStore();
    const themeName = computed(() => store.state.theme.name);
    const financingIcon = computed(() => store.getters["theme/financingIcon"]);
    const investorsIcon = computed(() => store.getters["theme/investorsIcon"]);
    const manageIcon = computed(() => store.getters["theme/manageIcon"]);
    const starIcon = computed(() => store.getters["theme/starIcon"]);

    const data = project.init();
    const { 
      version1, 
      version2,
      mainIndexData,
      announcementList, 
      projectIntroduction,
      operateCashFlowTotal, 
      financeCashFlowTotal, 
      investorCashFlowTotal, 
      axCashFlowTotal,
      bottomTabIndex
    } = data;
    const topTabIndex = ref(0);
    const topSwiper = ref(null);
    const bottomSwiper = ref(null);

    let manageChart = null;
    let financeChart = null;
    let investorChart = null;
    let anxingChart = null;

    onMounted(() => {
      watchEffect(() => {
        if (themeName.value && version1.value && version2.value && Object.keys(operateCashFlowTotal.value).length > 0) {
          nextTick(() => {
            if (!manageChart) {
              manageChart = echarts.init(document.getElementById("manage-chart"));
            }
            projectCharts.updateManageChart(manageChart, version1, version2, operateCashFlowTotal, store);
          });
        } else {
          if (manageChart) {
            manageChart.dispose();
            manageChart = null;
          }
        }
      });
      watchEffect(() => {
        if (themeName.value && version1.value && version2.value && Object.keys(financeCashFlowTotal.value).length > 0) {
          nextTick(() => {
            if (!financeChart) {
              financeChart = echarts.init(document.getElementById("finance-chart"));
            }
            projectCharts.updateFinanceChart(financeChart, version1, version2, financeCashFlowTotal, store);
          });
        } else {
          if (financeChart) {
            financeChart.dispose();
            financeChart = null;
          }
        }
      });
      watchEffect(() => {
        if (themeName.value && version1.value && version2.value && Object.keys(investorCashFlowTotal.value).length > 0) {
          nextTick(() => {
            if (!investorChart) {
              investorChart = echarts.init(document.getElementById("investor-chart"));
            }
            projectCharts.updateInvestorChart(investorChart, version1, version2, investorCashFlowTotal, store);
          });
        } else {
          if (investorChart) {
            investorChart.dispose();
            investorChart = null;
          }
        }
      });
      watchEffect(() => {
        if (themeName.value && version1.value && version2.value && Object.keys(axCashFlowTotal.value).length > 0) {
          nextTick(() => {
            if (!anxingChart) {
              anxingChart = echarts.init(document.getElementById("anxing-chart"));
            }
            projectCharts.updateAnxingChart(anxingChart, version1, version2, axCashFlowTotal, store);
          });
        } else {
          if (anxingChart) {
            anxingChart.dispose();
            anxingChart = null;
          }
        }
      });
      watch(() => bottomTabIndex.value, (value) => bottomSwiper.value.$el.swiper.slideTo(value));
    });

    const onTopTabClick = (value) => {
      topTabIndex.value = value;
      topSwiper.value.$el.swiper.slideTo(value);
    };

    const onBottomTabClick = (value) => {
      bottomTabIndex.value = value;
    };

    const onTopChartChange = (event) => {
      topTabIndex.value = event.realIndex;
    };

    const onBottomChartChange = (event) => {
      bottomTabIndex.value = event.realIndex;
    };

    onBeforeUnmount(() => {
      if (manageChart) {
        manageChart.dispose();
        manageChart = null;
      }
      if (financeChart) {
        financeChart.dispose();
        financeChart = null;
      }
      if (investorChart) {
        investorChart.dispose();
        investorChart = null;
      }
      if (anxingChart) {
        anxingChart.dispose();
        anxingChart = null;
      }
    });

    return {
      financingIcon,
      investorsIcon,
      manageIcon,
      starIcon,
      announcementList,
      mainIndexData,
      projectIntroduction,
      operateCashFlowTotal,
      financeCashFlowTotal,
      investorCashFlowTotal,
      axCashFlowTotal,
      topTabIndex,
      bottomTabIndex,
      topSwiper,
      bottomSwiper,
      modules: [Autoplay],
      onTopTabClick,
      onBottomTabClick,
      onTopChartChange,
      onBottomChartChange
    };
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/main.scss";

.top {
  position: absolute;
  top: 88px;
  left: 488px;
  display: flex;
  width: 944px;
  height: 112px;

  .datas {
    display: flex;

    .item {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 8px;
      width: 160px;
      height: 100%;
      background-color: var(--main-background-color);

      .line {
        position: absolute;
        top: 0;
        left: 0;
        width: 2px;
        height: 100%;
        background: #00A3FF;
      }

      .title {
        margin-left: 24px;
        font-size: 16px;
        font-family: MicrosoftYaHei;
        color: var(--main-font-color);
        line-height: 21px;
      }

      .value {
        display: flex;
        margin-left: 24px;

        .num {
          font-size: 36px;
          font-family: JetBrainsMonoRoman-Bold, JetBrainsMonoRoman;
          font-weight: bold;
          line-height: 48px;
        }

        .unit {
          margin-top: 18px;
          margin-left: 4px;
          font-size: 18px;
          font-family: MicrosoftYaHei;
          color: var(--secondary-font-color);
          line-height: 24px;
        }
      }

      &.blue {
        .line {
          background-color: #00A3FF;
        }

        .value {
          .num {
            color: #00A3FF;
          }
        }
      }

      &.orange {
        .line {
          background-color: var(--brand-color);
        }

        .value {
          .num {
            color: var(--brand-color);
          }
        }
      }

      &.green {
        .line {
          background-color: #00FFC2;
        }

        .value {
          .num {
            color: #00FFC2;
          }
        }
      }
    }
  }

  .pictures {
    margin-left: 8px;
    width: 416px;
    height: 112px;
    background-color: var(--main-background-color);

    .item {
      margin-top: 8px;
      width: 128px;
      height: 96px;

      .picture {
        width: 128px;
        height: 96px;
        object-fit: cover;
      }    
    }
  }
}

.notice {
  position: absolute;
  top: 220px;
  left: 488px;
  @extend %horizontal;
  width: 944px;
  height: 40px;
  background: linear-gradient(90deg, rgba(255, 186, 39, 0) 0%, rgba(255, 186, 39, 0.4) 12%, rgba(255, 186, 39, 0.8) 51%, rgba(255, 186, 39, 0.4) 85%, rgba(255, 186, 39, 0) 100%);

  .icon {
    margin: 12px;
    width: 24px;
    height: 24px;
  }

  .content {
    flex: 1;
    min-width: 0;
    height: 100%;

    .item {
      @extend %horizontal;
      height: 40px;

      .text {
        margin: 0 16px;
        font-size: 16px;
        font-family: MicrosoftYaHeiSemibold;
        color: var(--main-font-color);
      }
    }
  }
}

.tabs {
  @extend %horizontal;
  width: 100%;
  height: 36px;

  .tab {
    @extend %center;
    flex: 1;
    height: 36px;
    border-bottom: 1px solid var(--secondary-border-color);

    .icon {
      margin-right: 8px;
      width: 18px;
      height: 18px;
    }

    .text {
      font-size: 14px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: var(--tip-font-color);
    }

    &.current {
      background: linear-gradient(360deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
      border-bottom: 1px solid var(--main-font-color);

      .text {
        color: var(--main-font-color);
      }
    }
  }

  .tab-separator {
    width: 1px;
    height: 100%;
    background-color: var(--secondary-border-color);
  }
}

.bottom {
  position: absolute;
  bottom: 16px;
  left: 488px;
  display: flex;
  flex-direction: column;

  .bottom-top {
    display: flex;
    flex-direction: column;
    height: 120px;

    .line {
      @extend %horizontal;
      width: 100%;
      height: 24px;

      .text {
        margin-left: 12px;
        margin-right: 12px;
        font-size: 12px;
        font-family: MicrosoftYaHei;
        color: var(--main-font-color);
        @include ellipsis();
      }
    }
  }

  .bottom-middle {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    width: 944px;
    height: 234px;
    background-color: var(--main-background-color);
  }

  .bottom-bottom {
    margin-top: 16px;
    width: 944px;
    height: 226px;
    background-color: var(--main-background-color);
  }

  .swiper {
    width: 944px;
    height: 198px;

    .item {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
    }

    &.down {
      height: 189px;
    }
  }

  .body {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
  }
}
</style>
