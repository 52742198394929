<template>
  <div class="project">
    <div class="inner" :style="style">
      <project-map />
      <warning-bg :type="warningType" />
      <div class="header">
        <project-header />
      </div>
      <div class="left">
        <project-left />
      </div>
      <project-middle />
      <div class="right">
        <project-right />
      </div>
      <warning-message :type="warningType" />
      <transition name="scale">
        <no-permission v-if="ready && !hasPermission" />
      </transition>
    </div>
  </div>
</template>

<script>
import { computed, onBeforeUnmount } from "vue";
import { useRoute } from "vue-router";
import user from "@/compositions/user";
import screen from "@/compositions/screen";
import project from "@/compositions/project";
import WarningBg from "@/components/common/WarningBg";
import WarningMessage from "@/components/common/WarningMessage";
import ProjectMap from "@/components/project/ProjectMap";
import ProjectHeader from "@/components/project/ProjectHeader";
import ProjectLeft from "@/components/project/ProjectLeft";
import ProjectMiddle from "@/components/project/ProjectMiddle";
import ProjectRight from "@/components/project/ProjectRight";
import NoPermission from "@/components/common/NoPermission";

export default {
  name: "Project",
  components: {
    WarningBg,
    WarningMessage,
    ProjectMap,
    ProjectHeader,
    ProjectLeft,
    ProjectMiddle,
    ProjectRight,
    NoPermission
  },
  setup() {
    const route = useRoute();
    const { ready } = user.init();
    const { style } = screen.init();
    const { hasPermission, selectProjectId, projectId, projectIntroduction } = project.init();

    const warningType = computed(() => {
      if (projectIntroduction.value.warningStatus === 1) {
        return "warning";
      } else if (projectIntroduction.value.warningStatus === 2) {
        return "danger";
      } else {
        return "";
      }
    });

    projectId.value = Number(route.query.id);
    selectProjectId.value = `project-${route.query.id}`;

    onBeforeUnmount(() => {
      project.destroy();
    });

    return {
      style,
      warningType,
      ready,
      hasPermission
    };
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/main.scss";

.project {
  @extend %center;
  width: 100%;
  height: 100%;
  background: var(--project-bg) no-repeat center center;
}

.inner {
  position: relative;
  @extend %vertical;
  flex-shrink: 0;
  width: 1920px;
  height: 1080px;
  transform-origin: center center;
  overflow: hidden;
}

.header {
  display: flex;
  width: 100%;
  z-index: 2;
}

.left {
  position: absolute;
  top: 88px;
  left: 0;
  display: flex;
  width: 480px;
  height: 992px;
  z-index: 2;
}

.right {
  position: absolute;
  top: 88px;
  right: 0;
  display: flex;
  width: 480px;
  height: 992px;
  z-index: 2;
}
</style>

