<template>
  <div class="project-right">
    <div class="section top">
      <div class="top-item">
        <swiper class="swiper project" :modules="modules" :autoplay="{delay: 10000}" :touchStartPreventDefault="false">
          <swiper-slide class="item">
            <title-bar :icon="boardIcon" title="项目" />
            <div class="separator" />
            <div class="body">
              <div class="chart" id="volumn-sale-chart" v-if="Object.keys(projectVolumnSaleData).length > 0" />
              <div class="chart" v-else>
                <empty />
              </div>
              <div class="mask" />
              <div class="lines" v-if="Object.keys(projectVolumnSaleData).length > 0">
                <div class="line">
                  <span class="text">计容可售</span>
                  <span class="value">{{projectVolumnSaleData.volumnSaleArea}}m²</span>
                </div>
                <div class="line">
                  <span class="text">计划去化</span>
                  <span class="value">{{projectVolumnSaleData.volumnDesalination}}m²</span>
                </div>
                <div class="line">
                  <span class="text">实际去化</span>
                  <span class="value">{{projectVolumnSaleData.realDesalination}}m²</span>
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide class="item project">
            <title-bar :icon="boardIcon" title="项目" />
            <div class="separator" />
            <div class="body">
              <div class="chart" id="sale-chart" v-if="Object.keys(projectSaleData).length > 0" />
              <div class="chart" v-else>
                <empty />
              </div>
              <div class="mask" />
              <div class="lines" v-if="Object.keys(projectSaleData).length > 0">
                <div class="line">
                  <span class="text">{{version1 ? version1.name : ""}}货值</span>
                  <span class="value">{{projectSaleData.version1Value}}万元</span>
                </div>
                <div class="line">
                  <span class="text">{{version2 ? version2.name : ""}}签约</span>
                  <span class="value">{{projectSaleData.version2Subscription}}万元</span>
                </div>
                <div class="line">
                  <span class="text">{{version2 ? version2.name : ""}}回款</span>
                  <span class="value">{{projectSaleData.version2Payment}}万元</span>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="top-item">
        <div class="tabs">
          <button :class="['tab', topTabIndex === 0 ? 'current' : '']" @click="onTopTabClick(0)">
            <img class="icon" :src="topTabIndex === 0 ? coinActiveIcon : coinIcon" />
            <span class="text">账户余额</span>
          </button>
          <div class="tab-separator" />
          <button :class="['tab', topTabIndex === 1 ? 'current' : '']" @click="onTopTabClick(1)">
            <span class="text">经营应收应付</span>
          </button>
        </div>
        <div class="separator" />
        <swiper class="swiper balance" ref="topSwiper" @slideChange="onTopChartChange">
          <swiper-slide class="item">
            <template v-if="Object.keys(projectAccountDetail).length > 0">
              <div class="content">
                <img class="balance-icon" :src="balanceIcon" />
                <img class="available-icon" :src="availableIcon" />
                <img class="unavailable-icon" :src="unavailableIcon" />
                <img class="loan-icon" :src="loanIcon" />
                <img class="inandout-icon" :src="inandoutIcon" />
                <div class="content-top">
                  <div class="left">
                    <span class="title">总余额</span>
                    <div class="value">
                      <div class="value-text">{{projectAccountDetail.totalBalance}}</div>
                      <div class="value-unit">万</div>
                    </div>
                  </div>
                  <div class="separator-vertical" />
                  <div class="right">
                    <span class="title">可用余额</span>
                    <div class="value green">
                      <div class="value-text">{{projectAccountDetail.availableBalance}}</div>
                      <div class="value-unit">万</div>
                    </div>
                    <span class="title">不可用余额</span>
                    <div class="value red">
                      <div class="value-text">{{projectAccountDetail.unAvailableBalance}}</div>
                      <div class="value-unit">万</div>
                    </div>
                  </div>
                </div>
                <div class="separator" />
                <div class="content-bottom">
                  <div class="left">
                    <span class="title">贷款余额</span>
                    <div class="value">
                      <div class="value-text">{{projectAccountDetail.loanBalance}}</div>
                      <div class="value-unit">万</div>
                    </div>
                  </div>
                  <div class="separator-vertical" />
                  <div class="right">
                    <span class="title">往来余额</span>
                    <div class="value">
                      <div class="value-text">{{projectAccountDetail.payableContactMoney}}</div>
                      <div class="value-unit">万</div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <empty v-else />
          </swiper-slide>
          <swiper-slide class="item">
            <template v-if="mainIndexData.version2">
              <div class="content">
                <div class="legends">
                  <div class="legend">
                    <div class="icon" />
                    <span class="text">楼款应收未收(万元)</span>
                  </div>
                  <div class="legend">
                    <div class="icon green" />
                    <span class="text">总投应付未付(万元)</span>
                  </div>
                </div>
                <div class="datas" v-if="mainIndexData.version2">
                  <div class="data">
                    <span class="text">楼款应收未收</span>
                    <div class="bar">
                      <span class="bar-text">{{mainIndexData.version2.buildingPaymentReceivable}}</span>
                    </div>
                  </div>
                  <div class="data">
                    <span class="text">总投应付未付</span>
                    <div class="bar green" :style="{width: `${Math.min(mainIndexData.version2.investPaidTotal / mainIndexData.version2.buildingPaymentReceivable * 120, 120)}px`}">
                      <span class="bar-text">{{mainIndexData.version2.investPaidTotal}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <empty v-else />
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <div class="section middle1">
      <div class="tabs">
        <button :class="['tab', middleTopTabIndex === 0 ? 'current' : '']" @click="onMiddleTopTabClick(0)">
          <img class="icon" :src="calendarIcon" />
          <span class="text">分月销售情况</span>
        </button>
        <div class="tab-separator" />
        <button :class="['tab', middleTopTabIndex === 1 ? 'current' : '']" @click="onMiddleTopTabClick(1)">
          <img class="icon" :src="calendarIcon" />
          <span class="text">分月回款情况</span>
        </button>
        <div class="tab-separator" />
        <el-select class="selector" size="mini" placeholder="选择年份" v-model="year" :disabled="date2 != null">
          <el-option :label="item" :value="item" v-for="item in years" :key="item" />
        </el-select>
      </div>
      <div class="separator" />
      <swiper class="swiper" ref="middleTopSwiper" @slideChange="onMiddleTopChartChange">
        <swiper-slide class="item">
          <div class="body" id="year-sale-chart" v-if="Object.keys(yearSaleFinishData).length > 0" />
          <div class="body" v-else>
            <empty />
          </div>
        </swiper-slide>
        <swiper-slide class="item">
          <div class="body" id="year-repayment-chart" v-if="Object.keys(yearRepaymentData).length > 0" />
          <div class="body" v-else>
            <empty />
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div class="section middle2">
      <div class="tabs">
        <button :class="['tab', middleBottomTabIndex === 0 ? 'current' : '']" @click="onMiddleBottomTabClick(0)">
          <img class="icon" :src="apartpayIcon" />
          <span class="text">分月付款</span>
        </button>
        <div class="tab-separator" />
        <button :class="['tab', middleBottomTabIndex === 1 ? 'current' : '']" @click="onMiddleBottomTabClick(1)">
          <img class="icon" :src="costIcon" />
          <span class="text">全成本对比</span>
        </button>
        <div class="tab-separator" />
        <el-select class="selector" size="mini" placeholder="选择年份" v-model="year" :disabled="date2 != null">
          <el-option :label="item" :value="item" v-for="item in years" :key="item" />
        </el-select>
      </div>
      <swiper class="swiper" ref="middleBottomSwiper" @slideChange="onMiddleBottomChartChange">
        <swiper-slide class="item">
          <div class="body" id="year-payment-chart" v-if="Object.keys(yearPaymentData).length > 0" />
          <div class="body" v-else>
            <empty />
          </div>
        </swiper-slide>
        <swiper-slide class="item">
          <div class="body" id="cost-chart" v-if="Object.keys(mainIndexData).length > 0" />
          <div class="body" v-else>
            <empty />
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div class="section bottom">
      <div class="tabs">
        <button :class="['tab', bottomTabIndex === 0 ? 'current' : '']" @click="onBottomTabClick(0)">
          <img class="icon" :src="investmentIcon" />
          <span class="text">投资人投资概况</span>
        </button>
        <div class="tab-separator" />
        <button :class="['tab', bottomTabIndex === 1 ? 'current' : '']" @click="onBottomTabClick(1)" v-if="Object.keys(axMainIndexData).length > 0">
          <img class="icon" :src="investmentIcon" />
          <span class="text">安星投资概况</span>
        </button>
      </div>
      <swiper class="swiper bottom" ref="bottomSwiper" @slideChange="onBottomChartChange">
        <swiper-slide class="item">
          <div class="body" id="investor-investment-chart" v-if="Object.keys(investorMainIndexData).length > 0" />
          <div class="body" v-else>
            <empty />
          </div>
        </swiper-slide>
        <swiper-slide class="item">
          <div class="body" id="anxing-investment-chart" v-if="Object.keys(axMainIndexData).length > 0" />
          <div class="body" v-else>
            <empty />
          </div>
        </swiper-slide>
      </swiper>
    </div>
	</div>
</template>

<script>
import { ref, computed, watch, watchEffect, onMounted, onBeforeUnmount, nextTick } from "vue";
import { useStore } from "vuex";
import * as echarts from "echarts";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import TitleBar from "@/components/common/TitleBar";
import Empty from "@/components/common/Empty";
import project from "@/compositions/project";
import projectCharts from "@/charts/projectCharts";

export default {
  name: "ProjectLeft",
  components: {
    Swiper,
    SwiperSlide,
    TitleBar,
    Empty
  },
  setup() {
    const store = useStore();
    const themeName = computed(() => store.state.theme.name);
    const boardIcon = computed(() => store.getters["theme/boardIcon"]);
    const calendarIcon = computed(() => store.getters["theme/calendarIcon"]);
    const apartpayIcon = computed(() => store.getters["theme/apartpayIcon"]);
    const costIcon = computed(() => store.getters["theme/costIcon"]);
    const investmentIcon = computed(() => store.getters["theme/investmentIcon"]);
    const coinIcon = computed(() => store.getters["theme/coinIcon"]);
    const coinActiveIcon = computed(() => store.getters["theme/coinActiveIcon"]);
    const balanceIcon = computed(() => store.getters["theme/balanceIcon"]);
    const availableIcon = computed(() => store.getters["theme/availableIcon"]);
    const unavailableIcon = computed(() => store.getters["theme/unavailableIcon"]);
    const loanIcon = computed(() => store.getters["theme/loanIcon"]);
    const inandoutIcon = computed(() => store.getters["theme/inandoutIcon"]);

    const data = project.init();
    const { 
      years, 
      year, 
      version1, 
      version2,
      date2,
      mainIndexData,
      investorMainIndexData,
      axMainIndexData,
      projectVolumnSaleData, 
      projectSaleData,
      projectAccountDetail,
      yearSaleFinishData, 
      yearRepaymentData, 
      yearPaymentData,
      bottomTabIndex
    } = data;
    const topTabIndex = ref(0);
    const middleTopTabIndex = ref(0);
    const middleBottomTabIndex = ref(0);
    const topSwiper = ref(null);
    const middleTopSwiper = ref(null);
    const middleBottomSwiper = ref(null);
    const bottomSwiper = ref(null);

    let volumnSaleChart = null;
    let saleChart = null;
    let yearSaleChart = null;
    let yearRepaymentChart = null;
    let yearPaymentChart = null;
    let costChart = null;
    let investorInvestmentChart = null;
    let anxingInvestmentChart = null;

    onMounted(() => {
      watchEffect(() => {
        if (themeName.value && Object.keys(projectVolumnSaleData.value).length > 0) {
          nextTick(() => {
            if (!volumnSaleChart) {
              volumnSaleChart = echarts.init(document.getElementById("volumn-sale-chart"));
            }
            projectCharts.updateVolumnSaleChart(volumnSaleChart, projectVolumnSaleData, store);
          });
        } else {
          if (volumnSaleChart) {
            volumnSaleChart.dispose();
            volumnSaleChart = null;
          }
        }
      });
      watchEffect(() => {
        if (themeName.value && Object.keys(projectSaleData.value).length > 0) {
          nextTick(() => {
            if (!saleChart) {
              saleChart = echarts.init(document.getElementById("sale-chart"));
            }
            projectCharts.updateSaleChart(saleChart, projectSaleData, store);
          });
        } else {
          if (saleChart) {
            saleChart.dispose();
            saleChart = null;
          }
        }
      });
      watchEffect(() => {
        if (themeName.value && version2.value && Object.keys(yearSaleFinishData.value).length > 0) {
          nextTick(() => {
            if (!yearSaleChart) {
              yearSaleChart = echarts.init(document.getElementById("year-sale-chart"));
            }
            projectCharts.updateYearSaleChart(yearSaleChart, version2, yearSaleFinishData, store);
          });
        } else {
          if (yearSaleChart) {
            yearSaleChart.dispose();
            yearSaleChart = null;
          }
        }
      });
      watchEffect(() => {
        if (themeName.value && version2.value && Object.keys(yearRepaymentData.value).length > 0) {
          nextTick(() => {
            if (!yearRepaymentChart) {
              yearRepaymentChart = echarts.init(document.getElementById("year-repayment-chart"));
            }
            projectCharts.updateYearRepaymentChart(yearRepaymentChart, version2, yearRepaymentData, store);
          });
        } else {
          if (yearRepaymentChart) {
            yearRepaymentChart.dispose();
            yearRepaymentChart = null;
          }
        }
      });
      watchEffect(() => {
        if (themeName.value && Object.keys(yearPaymentData.value).length > 0) {
          nextTick(() => {
            if (!yearPaymentChart) {
              yearPaymentChart = echarts.init(document.getElementById("year-payment-chart"));
            }
            projectCharts.updateYearPaymentChart(yearPaymentChart, yearPaymentData, store);
          });
        } else {
          if (yearPaymentChart) {
            yearPaymentChart.dispose();
            yearPaymentChart = null;
          }
        }
      });
      watchEffect(() => {
        if (themeName.value && version1.value && version2.value && Object.keys(mainIndexData.value).length > 0) {
          nextTick(() => {
            if (!costChart) {
              costChart = echarts.init(document.getElementById("cost-chart"));
            }
            projectCharts.updateCostChart(costChart, version1, version2, mainIndexData, store);
          });
        } else {
          if (costChart) {
            costChart.dispose();
            costChart = null;
          }
        }
      });
      watchEffect(() => {
        if (themeName.value && version1.value && version2.value && Object.keys(investorMainIndexData.value).length > 0) {
          nextTick(() => {
            if (!investorInvestmentChart) {
              investorInvestmentChart = echarts.init(document.getElementById("investor-investment-chart"));
            }
            projectCharts.updateInvestorInvestmentChart(investorInvestmentChart, version1, version2, investorMainIndexData, store);
          });
        } else {
          if (investorInvestmentChart) {
            investorInvestmentChart.dispose();
            investorInvestmentChart = null;
          }
        }
      });
      watchEffect(() => {
        if (themeName.value && version1.value && version2.value && Object.keys(axMainIndexData.value).length > 0) {
          nextTick(() => {
            if (!anxingInvestmentChart) {
              anxingInvestmentChart = echarts.init(document.getElementById("anxing-investment-chart"));
            }
            projectCharts.updateAnxingInvestmentChart(anxingInvestmentChart, version1, version2, axMainIndexData, store);
          });
        } else {
          if (anxingInvestmentChart) {
            anxingInvestmentChart.dispose();
            anxingInvestmentChart = null;
          }
        }
      });
      watchEffect(() => {
        if (date2.value) {
          year.value = new Date(date2.value).getFullYear()
        }
      });
      watch(() => bottomTabIndex.value, (value) => bottomSwiper.value.$el.swiper.slideTo(value));
    });

    const onTopTabClick = (value) => {
      topTabIndex.value = value;
      topSwiper.value.$el.swiper.slideTo(value);
    };

    const onMiddleTopTabClick = (value) => {
      middleTopTabIndex.value = value;
      middleTopSwiper.value.$el.swiper.slideTo(value);
    };

    const onMiddleBottomTabClick = (value) => {
      middleBottomTabIndex.value = value;
      middleBottomSwiper.value.$el.swiper.slideTo(value);
    };

    const onBottomTabClick = (value) => {
      bottomTabIndex.value = value;
    };

    const onTopChartChange = (event) => {
      topTabIndex.value = event.realIndex;
    };

    const onMiddleTopChartChange = (event) => {
      middleTopTabIndex.value = event.realIndex;
    };

    const onMiddleBottomChartChange = (event) => {
      middleBottomTabIndex.value = event.realIndex;
    };

    const onBottomChartChange = (event) => {
      bottomTabIndex.value = event.realIndex;
    };

    onBeforeUnmount(() => {
      if (volumnSaleChart) {
        volumnSaleChart.dispose();
        volumnSaleChart = null;
      }
      if (saleChart) {
        saleChart.dispose();
        saleChart = null;
      }
      if (yearSaleChart) {
        yearSaleChart.dispose();
        yearSaleChart = null;
      }
      if (yearRepaymentChart) {
        yearRepaymentChart.dispose();
        yearRepaymentChart = null;
      }
      if (yearPaymentChart) {
        yearPaymentChart.dispose();
        yearPaymentChart = null;
      }
      if (costChart) {
        costChart.dispose();
        costChart = null;
      }
      if (investorInvestmentChart) {
        investorInvestmentChart.dispose();
        investorInvestmentChart = null;
      }
      if (anxingInvestmentChart) {
        anxingInvestmentChart.dispose();
        anxingInvestmentChart = null;
      }
    });

    return {
      boardIcon,
      calendarIcon,
      apartpayIcon,
      costIcon,
      investmentIcon,
      coinIcon,
      coinActiveIcon,
      balanceIcon,
      availableIcon,
      unavailableIcon,
      loanIcon,
      inandoutIcon,
      years,
      year,
      version1,
      version2,
      date2,
      mainIndexData,
      projectVolumnSaleData,
      projectSaleData,
      yearSaleFinishData,
      yearRepaymentData,
      yearPaymentData,
      investorMainIndexData,
      axMainIndexData,
      projectAccountDetail,
      topTabIndex,
      middleTopTabIndex,
      middleBottomTabIndex,
      bottomTabIndex,
      topSwiper,
      middleTopSwiper,
      middleBottomSwiper,
      bottomSwiper,
      modules: [Autoplay],
      onTopTabClick,
      onMiddleTopTabClick,
      onMiddleBottomTabClick,
      onBottomTabClick,
      onTopChartChange,
      onMiddleTopChartChange,
      onMiddleBottomChartChange,
      onBottomChartChange
    };
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/main.scss";

.project-right {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.tabs {
  @extend %horizontal;
  width: 100%;
  height: 36px;

  .tab {
    @extend %center;
    flex: 1;
    height: 36px;
    border-bottom: 1px solid var(--secondary-border-color);

    .icon {
      margin-right: 8px;
      width: 18px;
      height: 18px;
    }

    .text {
      font-size: 14px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: var(--tip-font-color);
    }

    &.current {
      background: linear-gradient(360deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
      border-bottom: 1px solid var(--main-font-color);

      .text {
        color: var(--main-font-color);
      }
    }
  }

  .tab-separator {
    width: 1px;
    height: 100%;
    background-color: var(--secondary-border-color);
  }
}

.section {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  margin-left: 16px;
  width: 456px;
  background-color: var(--main-background-color);

  &.top {
    flex-direction: row;
    height: 234px;
    background-color: transparent;

    .top-item {
      display: flex;
      flex-direction: column;
      width: 224px;
      height: 100%;
      background-color: var(--main-background-color);

      &:last-child  {
        margin-left: 8px;
      }

      .content {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;

        .balance-icon {
          position: absolute;
          top: 39px;
          left: 76px;
          width: 48px;
          height: 48px;
        }

        .available-icon {
          position: absolute;
          top: 22px;
          right: 4px;
          width: 32px;
          height: 32px;
        }

        .unavailable-icon {
          position: absolute;
          top: 73px;
          right: 4px;
          width: 32px;
          height: 32px;
        }

        .loan-icon {
          position: absolute;
          bottom: 24px;
          left: 88px;
          width: 32px;
          height: 32px;
        }

        .inandout-icon {
          position: absolute;
          bottom: 24px;
          right: 4px;
          width: 32px;
          height: 32px;
        }

        .content-top {
          display: flex;
          width: 100%;
          height: 121px;

          .left {
            display: flex;
            flex-direction: column;
            margin-top: 39px;
            margin-left: 20px;
            width: 91px;
          }

          .title {
            font-size: 12px;
            font-family: MicrosoftYaHei;
            color: var(--secondary-font-color);
            line-height: 16px;
          }

          .value {
            display: flex;
            margin-top: 2px;

            .value-text {
              margin-right: 2px;
              font-size: 16px;
              font-family: JetBrainsMonoRoman-Medium, JetBrainsMonoRoman;
              font-weight: bold;
              color: var(--main-font-color);
              line-height: 21px;
            }

            .value-unit {
              margin-top: 4px;
              font-size: 12px;
              font-family: MicrosoftYaHei;
              color: var(--tip-font-color);
              line-height: 16px;
            }

            &.green {
              margin-bottom: 10px;

              .value-text {
                margin-right: 2px;
                font-size: 16px;
                color: var(--success-color);
                line-height: 21px;
              }
              
              .value-unit {
                margin-top: 4px;
              }
            }

            &.red {

              .value-text {
                margin-right: 2px;
                font-size: 16px;
                color: var(--danger-color);
                line-height: 21px;
              }
              
              .value-unit {
                margin-top: 4px;
              }
            }
          }

          .separator-vertical {
            align-self: center;
            width: 1px;
            height: 100px;
            background-color: var(--secondary-border-color);
          }

          .right {
            display: flex;
            flex-direction: column;
            margin-top: 18px;
            margin-left: 8px;
          }
        }

        .separator {
          align-self: center;
          width: 200px;
          height: 1px;
          background-color: var(--secondary-border-color);
        }

        .content-bottom {
          display: flex;
          width: 100%;
          flex: 1;

          .left {
            display: flex;
            flex-direction: column;
            margin-top: 16px;
            margin-left: 20px;
            width: 91px;
          }

          .separator-vertical {
            align-self: center;
            width: 1px;
            height: 56px;
            background-color: var(--secondary-border-color);
          }

          .right {
            display: flex;
            flex-direction: column;
            margin-top: 18px;
            margin-left: 8px;
          }

          .title {
            font-size: 12px;
            font-family: MicrosoftYaHei;
            color: var(--secondary-font-color);
            line-height: 16px;
          }

          .value {
            display: flex;
            margin-top: 2px;

            .value-text {
              margin-right: 2px;
              font-size: 16px;
              font-family: JetBrainsMonoRoman-Medium, JetBrainsMonoRoman;
              font-weight: bold;
              color: var(--secondary-font-color);
              line-height: 21px;
            }
          }

          .value-unit {
            margin-top: 4px;
            font-size: 12px;
            font-family: MicrosoftYaHei;
            color: var(--tip-font-color);
            line-height: 16px;
          }
        }

        .legends {
          display: flex;
          flex-direction: column;
          margin-top: 8px;
          margin-left: 24px;

          .legend {
            @extend %horizontal;
            margin-bottom: 4px;

            .icon {
              width: 6px;
              height: 6px;
              background-color: #F74B0C;

              &.green {
                background-color: #1FCEA3;
              }
            }

            .text {
              margin-left: 4px;
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              color: var(--secondary-font-color);
              line-height: 17px;
            }
          }
        }

        .datas {
          display: flex;
          flex-direction: column;
          margin-top: 25px;
          margin-left: 12px;

          .data {
            @extend %horizontal;
            margin-bottom: 24px;

            .text {
              font-size: 12px;
              font-family: JetBrainsMono-Regular, JetBrainsMono;
              color: var(--main-font-color);
            }

            .bar {
              @extend %horizontal;
              margin-left: 8px;
              width: 120px;
              height: 36px;
              background-color: #F74B0C;

              .bar-text {
                margin-left: 7px;
                font-size: 12px;
                font-family: JetBrainsMono-Regular, JetBrainsMono;
                color: var(--main-font-color);
              }

              &.green {
                background-color: #28F3C2;

                .bar-text {
                  color: rgba(0, 0, 0, 0.8);
                  text-shadow: 0 0 2px #FFFFFF;
                }
              }
            }
          }
        }
      }
    }
  }

  &.middle1 {
    height: 234px;
  }

  &.middle2 {
    height: 234px;
  }

  &.bottom {
    margin-bottom: 0;
    height: 226px;
  }

  .swiper {
    width: 456px;
    height: 198px;

    .item {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
    }

    &.bottom {
      height: 189px;
    }

    &.project {
      width: 224px;
      height: 100% !important;

      .item {
        height: 234px !important;
      }
    }

    &.balance {
      width: 224px;
      height: 100% !important;

      .item {
        height: 198px !important;
      }
    }
  }

  .selector {
    margin: 0 10px;
    width: 80px;;
  }

  .body {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;

    .chart {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 80px;
      background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    }

    .lines {
      @extend %vertical;
      align-self: center;
      margin-top: 9px;
      z-index: 10;

      .line {
        @extend %horizontal;

        .text {
          font-size: 12px;
          font-family: MicrosoftYaHei;
          line-height: 16px;
          text-shadow: 0 0 2px #000000;
        }

        .value {
          margin-left: 4px;
          font-size: 12px;
          font-family: JetBrainsMono-Regular, JetBrainsMono;
          text-shadow: 0 0 2px #000000;
        }

        &:first-child {
          .text {
            color: #F74B0C;
          }

          .value {
            color: #F74B0C;
          }
        }

        &:nth-child(2) {
          .text {
            color: #28F3C2;
          }

          .value {
            color: #28F3C2;
          }
        }

        &:last-child {
          .text {
            color: #ACE1FF;
          }

          .value {
            color: #ACE1FF;
          }
        }
      }
    }
  }

  .tabs {
    display: flex;
    width: 100%;
    height: 36px;

    .tab {
      @extend %center;
      flex: 1;
      height: 36px;
      border-bottom: 1px solid var(--secondary-border-color);

      .icon {
        margin-right: 8px;
        width: 18px;
        height: 18px;
      }

      .text {
        font-size: 14px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: var(--tip-font-color);
      }

      &.current {
        background: linear-gradient(360deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
        border-bottom: 1px solid var(--main-font-color);

        .text {
          color: var(--main-font-color);
        }
      }
    }

    .tab-separator {
      width: 1px;
      height: 100%;
      background-color: var(--secondary-border-color);
    }
  }
}
</style>
