<template>
  <div class="project-left-middle">
    <div class="tabs">
      <button :class="['tab', tabIndex === 0 ? 'current' : '']" @click="onTabClick(0)">
        <img class="icon" :src="pieIcon" />
        <span class="text">{{version2 ? version2.name : ""}}货值分布</span>
      </button>
      <div class="tab-separator" />
      <button :class="['tab', tabIndex === 1 ? 'current' : '']" @click="onTabClick(1)">
        <img class="icon" :src="timelineIcon" />
        <span class="text">开发时间轴</span>
      </button>
      <div class="tab-separator" />
      <el-select v-show="tabIndex === 1" class="selector" size="mini" placeholder="选择期数" v-model="period">
        <el-option :label="`${item}期`" :value="item" v-for="item in periodList" :key="item" />
      </el-select>
    </div>
    <div class="separator" />
    <swiper class="body" ref="swiper" :touchStartPreventDefault="false" @slideChange="onChartChange">
      <swiper-slide class="item">
        <div class="body" id="sale-item-chart" v-if="Object.keys(saleItemData).length > 0" />
        <div class="body" v-else>
          <empty />
        </div>
      </swiper-slide>
      <swiper-slide class="item">
        <div class="body" v-if="Object.keys(mainIndexData).length > 0">
          <div class="legends">
            <div class="legend">
              <div class="point" />
              <span class="text">按时完成</span>
            </div>
            <div class="legend">
              <div class="point" />
              <span class="text">提前完成</span>
            </div>
            <div class="legend">
              <div class="point" />
              <span class="text">逾期完成</span>
            </div>
          </div>
          <div class="chart-container">
            <div class="chart" id="timeline-chart" />
            <div class="chart-separator" />
            <div class="chart-separator" />
            <div class="chart-separator" />
            <div class="chart-separator" />
            <div class="chart-separator" />
            <div class="date-item">
              <div :class="{status: true, ...getClass('nationalLandCertificateDiff')}" v-if="mainIndexData.diff.nationalLandCertificateDiff">
                <div class="bg" />
                <span class="text">{{getText('nationalLandCertificateDiff')}}</span>
              </div>
              <span class="date">{{mainIndexData.version2.landAcquisition}}</span>
            </div>
            <div class="date-item">
              <div :class="{status: true, ...getClass('constructionPermitDiff')}" v-if="mainIndexData.diff.constructionPermitDiff">
                <div class="bg" />
                <span class="text">{{getText('constructionPermitDiff')}}</span>
              </div>
              <span class="date">{{mainIndexData.version2.constructionPermit}}</span>
            </div>
            <div class="date-item">
              <div :class="{status: true, ...getClass('preSaleCertificateDiff')}" v-if="mainIndexData.diff.preSaleCertificateDiff">
                <div class="bg" />
                <span class="text">{{getText('preSaleCertificateDiff')}}</span>
              </div>
              <span class="date">{{mainIndexData.version2.preSaleCertificate}}</span>
            </div>
            <div class="date-item">
              <div :class="{status: true, ...getClass('structureCappingDiff')}" v-if="mainIndexData.diff.structureCappingDiff">
                <div class="bg" />
                <span class="text">{{getText('structureCappingDiff')}}</span>
              </div>
              <span class="date">{{mainIndexData.version2.structureCapping}}</span>
            </div>
            <div class="date-item">
              <div :class="{status: true, ...getClass('completionRecordDiff')}" v-if="mainIndexData.diff.completionRecordDiff">
                <div class="bg" />
                <span class="text">{{getText('completionRecordDiff')}}</span>
              </div>
              <span class="date">{{mainIndexData.version2.completionRecord}}</span>
            </div>
            <div class="date-item">
              <div :class="{status: true, ...getClass('deliveryOwnerDiff')}" v-if="mainIndexData.diff.deliveryOwnerDiff">
                <div class="bg" />
                <span class="text">{{getText('deliveryOwnerDiff')}}</span>
              </div>
              <span class="date">{{mainIndexData.version2.deliveryOwner}}</span>
            </div>
          </div>
        </div>
        <div class="body" v-else>
          <empty />
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { ref, computed, watchEffect, onMounted, onBeforeUnmount, nextTick } from "vue";
import { useStore } from "vuex";
import * as echarts from "echarts";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import Empty from "@/components/common/Empty";
import project from "@/compositions/project";
import projectCharts from "@/charts/projectCharts";

export default {
  name: "ProjectLeftMiddle",
  components: {
    Swiper,
    SwiperSlide,
    Empty
  },
  setup() {
    const store = useStore();
    const themeName = computed(() => store.state.theme.name);
    const pieIcon = computed(() => store.getters["theme/pieIcon"]);
    const timelineIcon = computed(() => store.getters["theme/timelineIcon"]);

    const data = project.init();
    const { version2, periodList, period, saleItemData, mainIndexData } = data;
    const tabIndex = ref(0);
    const swiper = ref(null);

    let saleItemChart = null;
    let timelineChart = null;

    onMounted(() => {
      watchEffect(() => {
        if (themeName.value && Object.keys(saleItemData.value).length > 0) {
          nextTick(() => {
            if (!saleItemChart) {
              saleItemChart = echarts.init(document.getElementById("sale-item-chart"));
            }
            projectCharts.updateSaleItemChart(saleItemChart, saleItemData, store);
          });
        } else {
          if (saleItemChart) {
            saleItemChart.dispose();
            saleItemChart = null;
          }
        }
      });
      watchEffect(() => {
        if (themeName.value && Object.keys(mainIndexData.value).length > 0) {
          nextTick(() => {
            if (!timelineChart) {
              timelineChart = echarts.init(document.getElementById("timeline-chart"));
            }
            projectCharts.updateTimelineChart(timelineChart, mainIndexData, store);
          });
        } else {
          if (timelineChart) {
            timelineChart.dispose();
            timelineChart = null;
          }
        }
      });
    });

    const getClass = (field) => {
      const value = mainIndexData.value.diff[field];
      return { danger: value > 0, success: value < 0 };
    };

    const getText = (field) => {
      const value = mainIndexData.value.diff[field];
      const preffix = value > 0 ? "逾期" : "提前";
      return `${preffix}${Math.abs(value)}天`;
    };

    const onTabClick = (value) => {
      tabIndex.value = value;
      swiper.value.$el.swiper.slideTo(value);
    };

    const onChartChange = (event) => {
      tabIndex.value = event.realIndex;
    };

    onBeforeUnmount(() => {
      if (saleItemChart) {
        saleItemChart.dispose();
        saleItemChart = null;
      }
      if (timelineChart) {
        timelineChart.dispose();
        timelineChart = null;
      }
    });

    return {
      pieIcon,
      timelineIcon,
      version2,
      periodList,
      period,
      mainIndexData,
      saleItemData,
      tabIndex,
      swiper,
      getClass,
      getText,
      onTabClick,
      onChartChange
    };
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/main.scss";

.project-left-middle {
  display: flex;
  flex-direction: column;
  width: 456px;
  height: 234px;
}

.tabs {
  @extend %horizontal;
  width: 100%;
  height: 36px;

  .tab {
    @extend %center;
    flex: 1;
    height: 36px;
    border-bottom: 1px solid var(--secondary-border-color);

    .icon {
      margin-right: 8px;
      width: 18px;
      height: 18px;
    }

    .text {
      font-size: 14px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: var(--tip-font-color);
    }

    &.current {
      background: linear-gradient(360deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
      border-bottom: 1px solid var(--main-font-color);

      .text {
        color: var(--main-font-color);
      }
    }
  }

  .tab-separator {
    width: 1px;
    height: 100%;
    background-color: var(--secondary-border-color);
  }
}

.item {
  display: flex;
  flex-direction: column;
  width: 456px;
  height: 198px;
}

.body {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;

  .legends {
    @extend %horizontal;
    width: 100%;
    height: 32px;

    .legend {
      @extend %horizontal;
      margin-left: 12px;
      
      .point {
        width: 6px;
        height: 6px;
        border-radius: 50%;
      }

      .text {
        margin-left: 4px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        color: var(--secondary-font-color);
      }

      &:first-child {
        .point {
          background-color: var(--main-font-color);
        }
      }

      &:nth-child(2) {
        .point {
          background-color: var(--success-color);
        }
      }

      &:nth-child(3) {
        .point {
          background-color: var(--danger-color);
        }
      }
    }
  }

  .chart-container {
    position: relative;
    width: 100%;
    flex: 1;

    .chart {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .chart-separator {
      position: absolute;
      top: 0;
      width: 1px;
      height: 130px;
      border: 1px dashed var(--secondary-border-color);

      &:nth-child(2) {
        left: 76px;
      }

      &:nth-child(3) {
        left: 150px;
      }

      &:nth-child(4) {
        left: 226px;
      }

      &:nth-child(5) {
        left: 304px;
      }

      &:nth-child(6) {
        left: 380px;
      }
    }

    .date-item {
      position: absolute;
      @extend %vertical;
      width: 80px;

      .status {
        position: relative;
        @extend %center;
        width: 56px;
        height: 18px;

        .bg {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 9px;
        }

        .text {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
        }

        &.success {
          .bg {
            background-color: var(--success-color);
            opacity: 0.1;
          }
          
          .text {
            color: var(--success-color);
          }
        }

        &.danger {
          .bg {
            background-color: var(--danger-color);
            opacity: 0.1;
          }
          
          .text {
            color: var(--danger-color);
          }
        }
      }

      .date {
        margin-top: 4px;
        font-size: 12px;
        font-family: JetBrainsMono-Regular, JetBrainsMono;
        color: var(--main-font-color);
        line-height: 16px;
        text-align: center;
      }

      &:nth-child(7) {
        bottom: 43px;
        left: 2px;
      }

      &:nth-child(8) {
        bottom: 49px;
        left: 76px;
      }

      &:nth-child(9) {
        bottom: 55px;
        left: 150px;
      }

      &:nth-child(10) {
        bottom: 70px;
        left: 224px;
      }

      &:nth-child(11) {
        bottom: 98px;
        left: 298px;
      }

      &:nth-child(12) {
        bottom: 160px;
        left: 370px;
      }
    }
  }
}

.selector {
  margin: 0 10px;
  width: 80px;
}
</style>
