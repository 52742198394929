<template>
  <div class="project-map" id="map-chart" />
</template>

<script>
import { computed, watchEffect, onMounted, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import * as echarts from "echarts";
import project from "@/compositions/project";
import projectCharts from "@/charts/projectCharts";

export default {
  name: "ProductMap",
  setup() {
    const store = useStore();
    const themeName = computed(() => store.state.theme.name);
    const data = project.init();
    const { projectIntroduction } = data;

    let mapChart = null;

    onMounted(() => {
      watchEffect(() => {
        if (Object.keys(projectIntroduction.value).length > 0) {
          if (!mapChart) {
            mapChart = echarts.init(document.getElementById("map-chart"));
          }
          projectCharts.updateMapChart(mapChart, themeName, projectIntroduction);
        } else {
          if (mapChart) {
            mapChart.dispose();
            mapChart = null;
          }
        }
      });
    });

    onBeforeUnmount(() => {
      if (mapChart) {
        mapChart.dispose();
        mapChart = null;
      }
    });
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/main.scss";

.project-map {
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>
