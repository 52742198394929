import * as echarts from "echarts";
import "echarts/extension/bmap/bmap";
import darkStyle from "@/mapStyles/dark";
import lightStyle from "@/mapStyles/light";

export default {
    updateMapChart(chart, themeName, projectIntroduction) {
        let center = [113.22028, 23.40813];
        let data = [];
        let coords = [];
        if (projectIntroduction.value.lnglat) {
            const point = JSON.parse(projectIntroduction.value.lnglat);
            data.push(point);
            center = [...point];
            center[1] -= 0.005;
        }
        if (projectIntroduction.value.position) {
            coords = JSON.parse(projectIntroduction.value.position);
        }
        const renderItem = (params, api) => {
            const points = [];
            for (const coord of coords) {
                points.push(api.coord(coord));
            }
            const color = api.visual("color");
            return {
                type: "polygon",
                shape: {
                    points: echarts.graphic.clipPointsByRect(points, {
                        x: params.coordSys.x,
                        y: params.coordSys.y,
                        width: params.coordSys.width,
                        height: params.coordSys.height
                    })
                },
                style: api.style({
                    fill: color,
                    stroke: echarts.color.lift(color)
                })
            };
        };
        const option = {
            bmap: {
                center: center,
                zoom: 16,
                roam: true,
                mapStyle: {
                    styleJson: themeName.value === "light" ? lightStyle : darkStyle
                }
            },
            series: [
                {
                    type: "scatter",
                    coordinateSystem: "bmap",
                    tooltip: {
                        show: false
                    },
                    symbol: "path://M189.868 24c16.27 0 29.505 13.006 29.88 29.191l0.008 0.706-0.001 11.498h667.861c22.626 0 41.013 18.165 41.378 40.712l0.006 0.684a41.405 41.405 0 0 1-9.265 26.102L792.734 289.27a11.502 11.502 0 0 0 0 14.501L919.735 460.15c14.411 17.744 11.714 43.815-6.025 58.23a41.375 41.375 0 0 1-26.094 9.267H219.755v372.56h46.593c16.506 0 29.888 13.386 29.888 29.897 0 16.276-13.002 29.514-29.182 29.889l-0.706 0.008h-152.46C97.38 960 84 946.615 84 930.103c0-16.275 13.001-29.514 29.182-29.888l0.705-0.009h46.093V53.896c0-16.51 13.382-29.896 29.888-29.896z",
                    symbolSize: 50,
                    symbolOffset: ["40%", "-50%"],
                    itemStyle: {
                        color: "#FF8517"
                    },
                    data: data
                },
                {
                    type: "custom",
                    coordinateSystem: "bmap",
                    renderItem: renderItem,
                    itemStyle: {
                      opacity: 0.5
                    },
                    animation: false,
                    silent: true,
                    data: [0, 1],
                    z: -10
                }
            ]
        };
        chart.setOption(option, true);
    },
    updateSaleItemChart(chart, saleItemData, store) {
        const colors = ["#F74B0C", "#28F3C2", "#ACE1FF", "#FFBE00", "#F01D1D"];
        const option = {
            tooltip: {
                trigger: "item"
            },
            legend: {
                type: "scroll",
                orient: "vertical",
                right: "10%",
                icon: "rect",
                itemWidth: 10,
                itemHeight: 10,
                textStyle: {
                    color: store.getters["theme/secondaryFontColor"]
                }
            },
            series: [
                {
                    type: "pie",
                    radius: "70%",
                    center: ["30%", "50%"],
                    label: {
                        show: false
                    },
                    data: saleItemData.value.map((item, index) => {
                        return {
                            name: item.name,
                            value: item.value,
                            itemStyle: {
                                color: colors[index]
                            }
                        };
                    })
                }
            ]
        };
        chart.setOption(option, true);
    },
    updateTimelineChart(chart, mainIndexData, store) {
        const getColor = (field) => {
            if (mainIndexData.value.diff[field] > 0) {
                return store.getters["theme/dangerColor"];
            } else if (mainIndexData.value.diff[field] < 0) {
                return store.getters["theme/successColor"];
            } else {
                return store.getters["theme/mainFontColor"];
            }
        };
        const option = {
            grid: {
                top: "0%",
                bottom: "20%",
                left: "0%",
                right: "0%"
            },
            xAxis: {
                type: "category",
                axisLabel: {
                    color: store.getters["theme/mainFontColor"]
                },
                axisLine: {
                    show: false
                },
                axisTick: {
                    show: false
                },
                data: ["土地证", "施工证", "预售证", "封顶", "竣工", "交付"]
            },
            yAxis: {
                show: false
            },
            series: [
                {
                    type: "line",
                    smooth: true,
                    itemStyle: {
                        color: store.getters["theme/mainBorderColor"]
                    },
                    data: [
                        {
                            itemStyle: {
                                color: getColor("nationalLandCertificateDiff")
                            },
                            value: 1
                        },
                        {
                            itemStyle: {
                                color: getColor("constructionPermitDiff")
                            },
                            value: 2
                        },
                        {
                            itemStyle: {
                                color: getColor("preSaleCertificateDiff")
                            },
                            value: 4
                        },
                        {
                            itemStyle: {
                                color: getColor("structureCappingDiff")
                            },
                            value: 8
                        },
                        {
                            itemStyle: {
                                color: getColor("completionRecordDiff")
                            },
                            value: 16
                        },
                        {
                            itemStyle: {
                                color: getColor("deliveryOwnerDiff")
                            },
                            value: 32
                        }
                    ]
                }
            ]
        };
        chart.setOption(option, true);
    },
    updateManageChart(chart, version1, version2, operateCashFlowTotal, store) {
        const option = {
            tooltip: {
                trigger: "item"
            },
            legend: {
                top: "5%",
                left: "5%",
                textStyle: {
                    color: store.getters["theme/secondaryFontColor"]
                }
            },
            grid: {
                bottom: "5%",
                left: "5%",
                containLabel: true
            },
            xAxis: {
                type: "category",
                axisLabel: {
                    color: store.getters["theme/mainFontColor"]
                },
                data: operateCashFlowTotal.value.date_list
            },
            yAxis: {
                type: "value",
                axisLabel: {
                    color: store.getters["theme/mainFontColor"]
                },
                splitLine: {
                    lineStyle: {
                        type: "dashed",
                        color: store.getters["theme/secondaryBorderColor"]
                    }
                }
            },
            series: [
                {
                    name: `${version1.value.name}经营累计现金流(万元)`,
                    type: "line",
                    itemStyle: {
                        color: "#F74B0C"
                    },
                    data: operateCashFlowTotal.value.version1
                },
                {
                    name: `${version2.value.name}经营累计现金流(万元)`,
                    type: "line",
                    itemStyle: {
                        color: "#28F3C2"
                    },
                    data: operateCashFlowTotal.value.version2
                }
            ]
        };
        chart.setOption(option, true);
    },
    updateFinanceChart(chart, version1, version2, financeCashFlowTotal, store) {
        const option = {
            tooltip: {
                trigger: "item"
            },
            legend: {
                top: "5%",
                left: "5%",
                textStyle: {
                    color: store.getters["theme/secondaryFontColor"]
                }
            },
            grid: {
                bottom: "5%",
                left: "5%",
                containLabel: true
            },
            xAxis: {
                type: "category",
                axisLabel: {
                    color: store.getters["theme/mainFontColor"]
                },
                data: financeCashFlowTotal.value.date_list
            },
            yAxis: {
                type: "value",
                axisLabel: {
                    color: store.getters["theme/mainFontColor"]
                },
                splitLine: {
                    lineStyle: {
                        type: "dashed",
                        color: store.getters["theme/secondaryBorderColor"]
                    }
                }
            },
            series: [
                {
                    name: `${version1.value.name}含融资累计现金流(万元)`,
                    type: "line",
                    itemStyle: {
                        color: "#F74B0C"
                    },
                    data: financeCashFlowTotal.value.version1
                },
                {
                    name: `${version2.value.name}含融资累计现金流(万元)`,
                    type: "line",
                    itemStyle: {
                        color: "#28F3C2"
                    },
                    data: financeCashFlowTotal.value.version2
                }
            ]
        };
        chart.setOption(option, true);
    },
    updateInvestorChart(chart, version1, version2, investorCashFlowTotal, store) {
        const option = {
            tooltip: {
                trigger: "item"
            },
            legend: {
                top: "5%",
                left: "5%",
                textStyle: {
                    color: store.getters["theme/secondaryFontColor"]
                }
            },
            grid: {
                bottom: "5%",
                left: "5%",
                containLabel: true
            },
            xAxis: {
                type: "category",
                axisLabel: {
                    color: store.getters["theme/mainFontColor"]
                },
                data: investorCashFlowTotal.value.date_list
            },
            yAxis: {
                type: "value",
                axisLabel: {
                    color: store.getters["theme/mainFontColor"]
                },
                splitLine: {
                    lineStyle: {
                        type: "dashed",
                        color: store.getters["theme/secondaryBorderColor"]
                    }
                }
            },
            series: [
                {
                    name: `${version1.value.name}投资人累计现金流(万元)`,
                    type: "line",
                    itemStyle: {
                        color: "#F74B0C"
                    },
                    data: investorCashFlowTotal.value.version1
                },
                {
                    name: `${version2.value.name}投资人累计现金流(万元)`,
                    type: "line",
                    itemStyle: {
                        color: "#28F3C2"
                    },
                    data: investorCashFlowTotal.value.version2
                }
            ]
        };
        chart.setOption(option, true);
    },
    updateAnxingChart(chart, version1, version2, axCashFlowTotal, store) {
        const option = {
            tooltip: {
                trigger: "item"
            },
            legend: {
                top: "5%",
                left: "5%",
                textStyle: {
                    color: store.getters["theme/secondaryFontColor"]
                }
            },
            grid: {
                bottom: "5%",
                left: "5%",
                containLabel: true
            },
            xAxis: {
                type: "category",
                axisLabel: {
                    color: store.getters["theme/mainFontColor"]
                },
                data: axCashFlowTotal.value.date_list
            },
            yAxis: {
                type: "value",
                axisLabel: {
                    color: store.getters["theme/mainFontColor"]
                },
                splitLine: {
                    lineStyle: {
                        type: "dashed",
                        color: store.getters["theme/secondaryBorderColor"]
                    }
                }
            },
            series: [
                {
                    name: `${version1.value.name}安星累计现金流(万元)`,
                    type: "line",
                    itemStyle: {
                        color: "#F74B0C"
                    },
                    data: axCashFlowTotal.value.version1
                },
                {
                    name: `${version2.value.name}安星累计现金流(万元)`,
                    type: "line",
                    itemStyle: {
                        color: "#28F3C2"
                    },
                    data: axCashFlowTotal.value.version2
                }
            ]
        };
        chart.setOption(option, true);
    },
    updateVolumnSaleChart(chart, projectVolumnSaleData, store) {
        const option = {
            tooltip: {
                trigger: "item"
            },
            series: [
                {
                    type: "gauge",
                    startAngle: 90,
                    endAngle: -270,
                    max: projectVolumnSaleData.value.volumnSaleArea,
                    radius: "90%",
                    center: ["50%", "52%"],
                    pointer: {
                        show: false
                    },
                    progress: {
                        show: true,
                        overlap: false,
                        clip: false
                    },
                    axisLine: {
                        lineStyle: {
                            width: 10,
                            color: [[1, "#FFFFFF"]],
                            opacity: 0.1
                        }
                    },
                    splitLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: false
                    },
                    data: [
                        {
                            name: "计容可售面积",
                            itemStyle: {
                                color: "#F74B0C"
                            },
                            value: projectVolumnSaleData.value.volumnSaleArea,
                            title: {
                                show: false
                            },
                            detail: {
                                offsetCenter: ["0%", "-20%"],
                                fontSize: 12,
                                fontWeight: "normal",
                                color: store.getters["theme/brandColor"],
                                formatter: "计容可售面积"
                            }
                        }
                    ]
                },
                {
                    type: "gauge",
                    startAngle: 90,
                    endAngle: -270,
                    max: projectVolumnSaleData.value.volumnSaleArea,
                    radius: "77%",
                    center: ["50%", "52%"],
                    pointer: {
                        show: false
                    },
                    progress: {
                        show: true,
                        overlap: false,
                        clip: false
                    },
                    axisLine: {
                        lineStyle: {
                            width: 10,
                            color: [[1, "#FFFFFF"]],
                            opacity: 0.1
                        }
                    },
                    splitLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: false
                    },
                    data: [
                        {
                            name: "计划去化",
                            itemStyle: {
                                color: "#28F3C2"
                            },
                            value: projectVolumnSaleData.value.volumnDesalination,
                            title: {
                                show: false
                            },
                            detail: {
                                offsetCenter: ["0%", "0%"],
                                fontSize: 12,
                                fontWeight: "normal",
                                color: store.getters["theme/mainFontColor"],
                                formatter: (value) => `计划去化 ${projectVolumnSaleData.value.volumnDesalinationRate}%`
                            }
                        }
                    ]
                },
                {
                    type: "gauge",
                    startAngle: 90,
                    endAngle: -270,
                    max: projectVolumnSaleData.value.volumnSaleArea,
                    radius: "64%",
                    center: ["50%", "52%"],
                    pointer: {
                        show: false
                    },
                    progress: {
                        show: true,
                        overlap: false,
                        clip: false
                    },
                    axisLine: {
                        lineStyle: {
                            width: 10,
                            color: [[1, "#FFFFFF"]],
                            opacity: 0.1
                        }
                    },
                    splitLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: false
                    },
                    data: [
                        {
                            name: "实际去化",
                            itemStyle: {
                                color: "#ACE1FF"
                            },
                            value: projectVolumnSaleData.value.realDesalination,
                            title: {
                                show: false
                            },
                            detail: {
                                offsetCenter: ["0%", "30%"],
                                fontSize: 12,
                                fontWeight: "normal",
                                color: store.getters["theme/mainFontColor"],
                                formatter: (value) => `实际去化 ${projectVolumnSaleData.value.realDesalinationRate}%`
                            }
                        }
                    ]
                }
            ]
        };
        chart.setOption(option, true);
    },
    updateSaleChart(chart, projectSaleData, store) {
        const option = {
            tooltip: {
                trigger: "item"
            },
            series: [
                {
                    type: "gauge",
                    startAngle: 90,
                    endAngle: -270,
                    min: 0,
                    max: projectSaleData.value.version1Value,
                    radius: "90%",
                    center: ["50%", "52%"],
                    pointer: {
                        show: false
                    },
                    progress: {
                        show: true,
                        overlap: false,
                        clip: false
                    },
                    axisLine: {
                        lineStyle: {
                            width: 10,
                            color: [[1, "#FFFFFF"]],
                            opacity: 0.1
                        }
                    },
                    splitLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: false
                    },
                    data: [
                        {
                            name: "销售金额",
                            itemStyle: {
                                color: "#F74B0C"
                            },
                            value: projectSaleData.value.version1Value,
                            title: {
                                show: false
                            },
                            detail: {
                                offsetCenter: ["0%", "-20%"],
                                fontSize: 12,
                                fontWeight: "normal",
                                color: store.getters["theme/brandColor"],
                                formatter: "销售金额"
                            }
                        },
                    ]
                },
                {
                    type: "gauge",
                    startAngle: 90,
                    endAngle: -270,
                    min: 0,
                    max: projectSaleData.value.version1Value,
                    radius: "77%",
                    center: ["50%", "52%"],
                    pointer: {
                        show: false
                    },
                    progress: {
                        show: true,
                        overlap: false,
                        clip: false
                    },
                    axisLine: {
                        lineStyle: {
                            width: 10,
                            color: [[1, "#FFFFFF"]],
                            opacity: 0.1
                        }
                    },
                    splitLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: false
                    },
                    data: [
                        {
                            name: "签约完成",
                            itemStyle: {
                                color: "#28F3C2"
                            },
                            value: projectSaleData.value.version2Subscription,
                            title: {
                                show: false
                            },
                            detail: {
                                offsetCenter: ["0%", "0%"],
                                fontSize: 12,
                                fontWeight: "normal",
                                color: store.getters["theme/mainFontColor"],
                                formatter: (value) => `签约完成 ${projectSaleData.value.subscriptionCompletedRate}%`
                            }
                        }
                    ]
                },
                {
                    type: "gauge",
                    startAngle: 90,
                    endAngle: -270,
                    min: 0,
                    max: projectSaleData.value.version1Value,
                    radius: "64%",
                    center: ["50%", "52%"],
                    pointer: {
                        show: false
                    },
                    progress: {
                        show: true,
                        overlap: false,
                        clip: false
                    },
                    axisLine: {
                        lineStyle: {
                            width: 10,
                            color: [[1, "#FFFFFF"]],
                            opacity: 0.1
                        }
                    },
                    splitLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: false
                    },
                    data: [
                        {
                            name: "回款比率",
                            itemStyle: {
                                color: "#ACE1FF"
                            },
                            value: projectSaleData.value.version2Payment,
                            title: {
                                show: false
                            },
                            detail: {
                                offsetCenter: ["0%", "30%"],
                                fontSize: 12,
                                fontWeight: "normal",
                                color: store.getters["theme/mainFontColor"],
                                formatter: (value) => `回款比率 ${projectSaleData.value.paymentRate}%`
                            }
                        }
                    ]
                }
            ]
        };
        chart.setOption(option, true);
    },
    updateYearSaleChart(chart, version2, yearSaleFinishData, store) {
        const option = {
            tooltip: {
                trigger: "item"
            },
            legend: {
                icon: "rect",
                itemWidth: 10,
                itemHeight: 10,
                top: "5%",
                textStyle: {
                    color: store.getters["theme/secondaryFontColor"]
                }
            },
            grid: {
                bottom: "10%",
                left: "10%",
                containLabel: true
            },
            xAxis: {
                type: "category",
                axisLabel: {
                    interval: 0,
                    color: store.getters["theme/mainFontColor"]
                },
                data: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"]
            },
            yAxis: {
                type: "value",
                axisLabel: {
                    color: store.getters["theme/mainFontColor"]
                },
                splitLine: {
                    lineStyle: {
                        type: "dashed",
                        color: store.getters["theme/secondaryBorderColor"]
                    }
                }
            },
            series: [
                {
                    type: "bar",
                    name: "完成部分(万元)",
                    stack: "完成情况",
                    itemStyle: {
                        color: "#F74B0C"
                    },
                    data: yearSaleFinishData.value.finishList
                },
                {
                    type: "bar",
                    name: `${version2.value.name}不达目标(万元)`,
                    stack: "完成情况",
                    itemStyle: {
                        color: "#28F3C2"
                    },
                    data: yearSaleFinishData.value.notStandardList
                },
                {
                    type: "bar",
                    name: `${version2.value.name}超额完成(万元)`,
                    stack: "完成情况",
                    itemStyle: {
                        color: "#ACE1FF"
                    },
                    data: yearSaleFinishData.value.overCompletedList
                }
            ]
        };
        chart.setOption(option, true);
    },
    updateYearRepaymentChart(chart, version2, yearRepaymentData, store) {
        const option = {
            tooltip: {
                trigger: "item"
            },
            legend: {
                icon: "rect",
                itemWidth: 10,
                itemHeight: 10,
                top: "5%",
                textStyle: {
                    color: store.getters["theme/secondaryFontColor"]
                }
            },
            grid: {
                bottom: "10%",
                left: "10%",
                containLabel: true
            },
            xAxis: {
                type: "category",
                axisLabel: {
                    interval: 0,
                    color: store.getters["theme/mainFontColor"]
                },
                data: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"]
            },
            yAxis: {
                type: "value",
                axisLabel: {
                    color: store.getters["theme/mainFontColor"]
                },
                splitLine: {
                    lineStyle: {
                        type: "dashed",
                        color: store.getters["theme/secondaryBorderColor"]
                    }
                }
            },
            series: [
                {
                    type: "bar",
                    name: "完成部分(万元)",
                    stack: "完成情况",
                    itemStyle: {
                        color: "#F74B0C"
                    },
                    data: yearRepaymentData.value.finishList
                },
                {
                    type: "bar",
                    name: `${version2.value.name}不达目标(万元)`,
                    stack: "完成情况",
                    itemStyle: {
                        color: "#28F3C2"
                    },
                    data: yearRepaymentData.value.notStandardList
                },
                {
                    type: "bar",
                    name: `${version2.value.name}超额完成(万元)`,
                    stack: "完成情况",
                    itemStyle: {
                        color: "#ACE1FF"
                    },
                    data: yearRepaymentData.value.overCompletedList
                }
            ]
        };
        chart.setOption(option, true);
    },
    updateYearPaymentChart(chart, yearPaymentData, store) {
        const data = yearPaymentData.value.paymentList2 ? 
                        yearPaymentData.value.paymentList2.map(data => -data) : [] // 只取动态版, 取反正数显示

        const option = {
            tooltip: {
                trigger: "item"
            },
            legend: {
                icon: "rect",
                itemWidth: 10,
                itemHeight: 10,
                top: "5%",
                left: "8%",
                textStyle: {
                    color: store.getters["theme/secondaryFontColor"]
                }
            },
            grid: {
                bottom: "10%",
                left: "10%",
                containLabel: true
            },
            xAxis: {
                type: "category",
                axisLabel: {
                    interval: 0,
                    color: store.getters["theme/mainFontColor"]
                },
                data: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"]
            },
            yAxis: {
                type: "value",
                axisLabel: {
                    color: store.getters["theme/mainFontColor"]
                },
                splitLine: {
                    lineStyle: {
                        type: "dashed",
                        color: store.getters["theme/secondaryBorderColor"]
                    }
                }
            },
            series: [
                {
                    type: "bar",
                    name: "付款金额(万元)",
                    itemStyle: {
                        color: " #ACE1FF"
                    },
                    data: data
                }
            ]
        };
        chart.setOption(option, true);
    },
    updateCostChart(chart, version1, version2, mainIndexData, store) {
        const option = {
            tooltip: {
                trigger: "item"
            },
            legend: {
                icon: "rect",
                itemWidth: 10,
                itemHeight: 10,
                top: "5%",
                left: "8%",
                textStyle: {
                    color: store.getters["theme/secondaryFontColor"]
                }
            },
            grid: {
                bottom: "10%",
                left: "10%",
                containLabel: true
            },
            xAxis: {
                type: "category",
                axisLabel: {
                    interval: 0,
                    color: store.getters["theme/mainFontColor"]
                },
                data: ["土地成本", "建安成本", "销售费用", "管理费用", "财务费用", "经营税金"]
            },
            yAxis: {
                type: "value",
                axisLabel: {
                    color: store.getters["theme/mainFontColor"]
                },
                splitLine: {
                    lineStyle: {
                        type: "dashed",
                        color: store.getters["theme/secondaryBorderColor"]
                    }
                }
            },
            series: [
                {
                    type: "bar",
                    name: `${version1.value.name}(万元)`,
                    itemStyle: {
                        color: "#F74B0C"
                    },
                    data: [
                        mainIndexData.value.version1.landCost,
                        mainIndexData.value.version1.buildingInstallCost,
                        mainIndexData.value.version1.saleCost,
                        mainIndexData.value.version1.manageCost,
                        mainIndexData.value.version1.financeTotal,
                        mainIndexData.value.version1.operateVatAmount
                    ]
                },
                {
                    type: "bar",
                    name: `${version2.value.name}(万元)`,
                    itemStyle: {
                        color: "#28F3C2"
                    },
                    data: [
                        mainIndexData.value.version2.landCost,
                        mainIndexData.value.version2.buildingInstallCost,
                        mainIndexData.value.version2.saleCost,
                        mainIndexData.value.version2.manageCost,
                        mainIndexData.value.version2.financeTotal,
                        mainIndexData.value.version2.operateVatAmount
                    ]
                }
            ]
        };
        chart.setOption(option, true);
    },
    updateInvestorInvestmentChart(chart, version1, version2, investorMainIndexData, store) {
        const option = {
            tooltip: {
                trigger: "item"
            },
            legend: {
                icon: "rect",
                itemWidth: 10,
                itemHeight: 10,
                top: "5%",
                left: "8%",
                textStyle: {
                    color: store.getters["theme/secondaryFontColor"]
                }
            },
            grid: {
                bottom: "10%",
                left: "10%",
                containLabel: true
            },
            xAxis: {
                type: "value",
                axisLabel: {
                    color: store.getters["theme/mainFontColor"]
                },
                splitLine: {
                    lineStyle: {
                        type: "dashed",
                        color: store.getters["theme/secondaryBorderColor"]
                    }
                }
            },
            yAxis: {
                type: "category",
                axisLabel: {
                    color: store.getters["theme/mainFontColor"]
                },
                data: [version1.value.name, version2.value.name]
            },
            series: [
                {
                    type: "bar",
                    name: "投资峰值(万元)",
                    stack: "投资",
                    itemStyle: {
                        color: "#F74B0C"
                    },
                    data: [investorMainIndexData.value.version1.investPeak, investorMainIndexData.value.version2.investPeak]
                },
                {
                    type: "bar",
                    name: "投资收益(万元)",
                    stack: "投资",
                    itemStyle: {
                        color: "#28F3C2"
                    },
                    data: [investorMainIndexData.value.version1.investIncome, investorMainIndexData.value.version2.investIncome]
                }
            ]
        };
        chart.setOption(option, true);
    },
    updateAnxingInvestmentChart(chart, version1, version2, axMainIndexData, store) {
        const option = {
            tooltip: {
                trigger: "item"
            },
            legend: {
                icon: "rect",
                itemWidth: 10,
                itemHeight: 10,
                top: "5%",
                left: "8%",
                textStyle: {
                    color: store.getters["theme/secondaryFontColor"]
                }
            },
            grid: {
                bottom: "10%",
                left: "10%",
                containLabel: true
            },
            xAxis: {
                type: "value",
                axisLabel: {
                    color: store.getters["theme/mainFontColor"]
                },
                splitLine: {
                    lineStyle: {
                        type: "dashed",
                        color: store.getters["theme/secondaryBorderColor"]
                    }
                }
            },
            yAxis: {
                type: "category",
                axisLabel: {
                    color: store.getters["theme/mainFontColor"]
                },
                data: [version1.value.name, version2.value.name]
            },
            series: [
                {
                    type: "bar",
                    name: "投资峰值(万元)",
                    stack: "投资",
                    itemStyle: {
                        color: "#F74B0C"
                    },
                    data: [axMainIndexData.value.version1.investPeak, axMainIndexData.value.version2.investPeak]
                },
                {
                    type: "bar",
                    name: "投资收益(万元)",
                    stack: "投资",
                    itemStyle: {
                        color: "#28F3C2"
                    },
                    data: [axMainIndexData.value.version1.investIncome, axMainIndexData.value.version2.investIncome]
                }
            ]
        };
        chart.setOption(option, true);
    }
}