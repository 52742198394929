<template>
  <div class="project-table">
    <swiper class="tabs" ref="swiper" :slides-per-view="5.8">
      <swiper-slide :class="['tab', tab.id === currentTabId ? 'current' : '']" v-for="tab in tabs" :key="tab.id" @click="onTabClick(tab)">
        <span class="text">{{tab.name}}</span>
        <div class="line" v-if="tab.id === currentTabId" />
      </swiper-slide>
      <button class="btn-prev" @click.stop="onPrevClick">
        <img class="right-icon left" :src="rightIcon" />
      </button>
      <button class="btn-next" @click.stop="onNextClick">
        <img class="right-icon right" :src="rightIcon" />
      </button>
    </swiper>
    <template v-if="version1 && version2 && Object.keys(mainIndexData).length > 0">
      <div class="table" v-if="currentTabId === 1">
        <div class="row title">
          <div class="cell">
            <span class="text">核心指标</span>
          </div>
          <div class="separator-vertical" />
          <div class="cell">
            <span class="text">{{version1.name}}</span>
          </div>
          <div class="separator-vertical" />
          <div class="cell">
            <span class="text">{{version2.name}}</span>
          </div>
          <div class="separator-vertical" />
          <div class="cell">
            <span class="text">B-A</span>
          </div>
        </div>
        <swiper class="body" :modules="modules" direction="vertical" :autoplay="{delay: 5000, disableOnInteraction: false}" :slides-per-view="10">
          <swiper-slide class="row">
            <div class="cell">
              <span class="text">净利润</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version1.netProfit}}万元</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version2.netProfit}}万元</span>
            </div>
            <div class="cell" :class="getClass('netProfit')">
              <span class="text">{{mainIndexData.diff.netProfit}}</span>
            </div>
          </swiper-slide>
          <swiper-slide class="row odd">
            <div class="cell">
              <span class="text">净利率</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version1.netInterestRate}}%</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version2.netInterestRate}}%</span>
            </div>
            <div class="cell" :class="getClass('netInterestRate')">
              <span class="text">{{mainIndexData.diff.netInterestRate}}%</span>
            </div>
          </swiper-slide>
          <swiper-slide class="row">
            <div class="cell">
              <span class="text">经营IRR</span>
            </div>
            <div class="cell">
              <span class="text">{{ formatIrr(mainIndexData.version1.operateIrr) }}</span>
            </div>
            <div class="cell">
              <span class="text">{{ formatIrr(mainIndexData.version2.operateIrr) }}</span>
            </div>
            <div class="cell" :class="getClass('operateIrr')">
              <span class="text">{{ formatPercentage(mainIndexData.diff.operateIrr) }}</span>
            </div>
          </swiper-slide>
          <swiper-slide class="row odd">
            <div class="cell">
              <span class="text">经营回正周期</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version1.operateRecoveryCycle}}个月</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version2.operateRecoveryCycle}}个月</span>
            </div>
            <div class="cell" :class="getClass('operateRecoveryCycle')">
              <span class="text">{{mainIndexData.diff.operateRecoveryCycle}}个月</span>
            </div>
          </swiper-slide>
          <swiper-slide class="row">
            <div class="cell">
              <span class="text">经营资金峰值</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version1.operateCashPeak}}万元</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version2.operateCashPeak}}万元</span>
            </div>
            <div class="cell" :class="getClass('operateCashPeak')">
              <span class="text">{{mainIndexData.diff.operateCashPeak}}</span>
            </div>
          </swiper-slide>
          <swiper-slide class="row odd">
            <div class="cell">
              <span class="text">融资IRR</span>
            </div>
            <div class="cell">
              <span class="text">{{ formatIrr(mainIndexData.version1.financingIrr) }}</span>
            </div>
            <div class="cell">
              <span class="text">{{ formatIrr(mainIndexData.version2.financingIrr) }}</span>
            </div>
            <div class="cell" :class="getClass('financingIrr')">
              <span class="text">{{ formatPercentage(mainIndexData.diff.financingIrr) }}</span>
            </div>
          </swiper-slide>
          <swiper-slide class="row">
            <div class="cell">
              <span class="text">融资回正周期</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version1.financingRecoveryCycle}}个月</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version2.financingRecoveryCycle}}个月</span>
            </div>
            <div class="cell" :class="getClass('financingRecoveryCycle')">
              <span class="text">{{mainIndexData.diff.financingRecoveryCycle}}</span>
            </div>
          </swiper-slide>
          <swiper-slide class="row odd">
            <div class="cell">
              <span class="text">融资资金峰值</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version1.financingCashPeak}}万元</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version2.financingCashPeak}}万元</span>
            </div>
            <div class="cell" :class="getClass('financingCashPeak')">
              <span class="text">{{mainIndexData.diff.financingCashPeak}}</span>
            </div>
          </swiper-slide>
          <swiper-slide class="row">
            <div class="cell">
              <span class="text">销管费率</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version1.salesManagementFeeRate}}%</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version2.salesManagementFeeRate}}%</span>
            </div>
            <div class="cell" :class="getClass('salesManagementFeeRate')">
              <span class="text">{{mainIndexData.diff.salesManagementFeeRate}}%</span>
            </div>
          </swiper-slide>
          <swiper-slide class="row odd">
            <div class="cell">
              <span class="text">总税负率</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version1.totalTaxRate}}%</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version2.totalTaxRate}}%</span>
            </div>
            <div class="cell" :class="getClass('totalTaxRate')">
              <span class="text">{{mainIndexData.diff.totalTaxRate}}%</span>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="table" v-else-if="currentTabId === 2">
        <div class="row title">
          <div class="cell">
            <span class="text">经济指标</span>
          </div>
          <div class="separator-vertical" />
          <div class="cell">
            <span class="text">{{version1.name}}</span>
          </div>
          <div class="separator-vertical" />
          <div class="cell">
            <span class="text">{{version2.name}}</span>
          </div>
          <div class="separator-vertical" />
          <div class="cell">
            <span class="text">B/A</span>
          </div>
        </div>
        <swiper class="body" :modules="modules" direction="vertical" :autoplay="{delay: 5000, disableOnInteraction: false}" :slides-per-view="10">
          <swiper-slide class="row">
            <div class="cell">
              <span class="text">容积率</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version1.volumnRate}}</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version2.volumnRate}}</span>
            </div>
            <div class="cell" :class="getClass('volumnRate')">
              <span class="text">{{mainIndexData.diff.volumnRate}}</span>
            </div>
          </swiper-slide>
          <swiper-slide class="row odd">
            <div class="cell">
              <span class="text">净用地面积</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version1.netLandArea}}m²</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version2.netLandArea}}m²</span>
            </div>
            <div class="cell" :class="getClass('netLandArea')">
              <span class="text">{{mainIndexData.diff.netLandArea}}%</span>
            </div>
          </swiper-slide>
          <swiper-slide class="row">
            <div class="cell">
              <span class="text">计容可售面积</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version1.volumnSaleArea}}m²</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version2.volumnSaleArea}}m²</span>
            </div>
            <div class="cell" :class="getClass('volumnSaleArea')">
              <span class="text">{{mainIndexData.diff.volumnSaleArea}}%</span>
            </div>
          </swiper-slide>
          <swiper-slide class="row odd">
            <div class="cell">
              <span class="text">计容面积</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version1.volumnArea}}m²</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version2.volumnArea}}m²</span>
            </div>
            <div class="cell" :class="getClass('volumnSaleArea')">
              <span class="text">{{mainIndexData.diff.volumnArea}}%</span>
            </div>
          </swiper-slide>
          <swiper-slide class="row">
            <div class="cell">
              <span class="text">总建筑面积</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version1.totalBuildingArea}}m²</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version2.totalBuildingArea}}m²</span>
            </div>
            <div class="cell" :class="getClass('totalBuildingArea')">
              <span class="text">{{mainIndexData.diff.totalBuildingArea}}%</span>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="table" v-else-if="currentTabId === 3">
        <div class="row title">
          <div class="cell">
            <span class="text">销售指标</span>
          </div>
          <div class="separator-vertical" />
          <div class="cell">
            <span class="text">{{version1.name}}</span>
          </div>
          <div class="separator-vertical" />
          <div class="cell">
            <span class="text">{{version2.name}}</span>
          </div>
          <div class="separator-vertical" />
          <div class="cell">
            <span class="text">B/A</span>
          </div>
        </div>
        <swiper class="body" :modules="modules" direction="vertical" :autoplay="{delay: 5000, disableOnInteraction: false}" :slides-per-view="10">
          <swiper-slide class="row">
            <div class="cell">
              <span class="text">总认购</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version1.totalSubscription}}万元</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version2.totalSubscription}}万元</span>
            </div>
            <div class="cell" :class="getClass('totalSubscription')">
              <span class="text">{{mainIndexData.diff.totalSubscription}}%</span>
            </div>
          </swiper-slide>
          <swiper-slide class="row odd">
            <div class="cell">
              <span class="text">总回款</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version1.totalPayment}}万元</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version2.totalPayment}}万元</span>
            </div>
            <div class="cell" :class="getClass('totalPayment')">
              <span class="text">{{mainIndexData.diff.totalPayment}}%</span>
            </div>
          </swiper-slide>
          <swiper-slide class="row">
            <div class="cell">
              <span class="text">不含车位销售面积</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version1.saleAreaExcludeParkSpace}}㎡</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version2.saleAreaExcludeParkSpace}}㎡</span>
            </div>
            <div class="cell" :class="getClass('saleAreaExcludeParkSpace')">
              <span class="text">{{mainIndexData.diff.saleAreaExcludeParkSpace}}%</span>
            </div>
          </swiper-slide>
          <swiper-slide class="row odd">
            <div class="cell">
              <span class="text">不含车位认购额</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version1.subscriptionAmountExcludeParkSpace}}万元</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version2.subscriptionAmountExcludeParkSpace}}万元</span>
            </div>
            <div class="cell" :class="getClass('subscriptionAmountExcludeParkSpace')">
              <span class="text">{{mainIndexData.diff.subscriptionAmountExcludeParkSpace}}%</span>
            </div>
          </swiper-slide>
          <swiper-slide class="row">
            <div class="cell">
              <span class="text">不含车位单价</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version1.unitPriceExcludeParkSpace}}元/㎡</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version2.unitPriceExcludeParkSpace}}元/㎡</span>
            </div>
            <div class="cell" :class="getClass('unitPriceExcludeParkSpace')">
              <span class="text">{{mainIndexData.diff.unitPriceExcludeParkSpace}}%</span>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="table" v-else-if="currentTabId === 4">
        <div class="row title">
          <div class="cell">
            <span class="text">总投指标</span>
          </div>
          <div class="separator-vertical" />
          <div class="cell">
            <span class="text">{{version1.name}}</span>
          </div>
          <div class="separator-vertical" />
          <div class="cell">
            <span class="text">{{version2.name}}</span>
          </div>
          <div class="separator-vertical" />
          <div class="cell">
            <span class="text">B/A</span>
          </div>
        </div>
        <swiper class="body" :modules="modules" direction="vertical" :autoplay="{delay: 5000, disableOnInteraction: false}" :slides-per-view="10">
          <swiper-slide class="row">
            <div class="cell">
              <span class="text">总投资</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version1.totalInvest}}万元</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version2.totalInvest}}万元</span>
            </div>
            <div class="cell" :class="getClass('totalInvest')">
              <span class="text">{{mainIndexData.diff.totalInvest}}%</span>
            </div>
          </swiper-slide>
          <swiper-slide class="row odd">
            <div class="cell">
              <span class="text">土地成本</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version1.landCost}}万元</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version2.landCost}}万元</span>
            </div>
            <div class="cell" :class="getClass('landCost')">
              <span class="text">{{mainIndexData.diff.landCost}}%</span>
            </div>
          </swiper-slide>
          <swiper-slide class="row">
            <div class="cell">
              <span class="text">建安成本</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version1.buildingInstallCost}}万元</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version2.buildingInstallCost}}万元</span>
            </div>
            <div class="cell" :class="getClass('buildingInstallCost')">
              <span class="text">{{mainIndexData.diff.buildingInstallCost}}%</span>
            </div>
          </swiper-slide>
          <swiper-slide class="row odd">
            <div class="cell">
              <span class="text">销售费用</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version1.saleCost}}万元</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version2.saleCost}}万元</span>
            </div>
            <div class="cell" :class="getClass('saleCost')">
              <span class="text">{{mainIndexData.diff.saleCost}}%</span>
            </div>
          </swiper-slide>
          <swiper-slide class="row">
            <div class="cell">
              <span class="text">管理费用</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version1.manageCost}}万元</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version2.manageCost}}万元</span>
            </div>
            <div class="cell" :class="getClass('manageCost')">
              <span class="text">{{mainIndexData.diff.manageCost}}%</span>
            </div>
          </swiper-slide>
          <swiper-slide class="row odd">
            <div class="cell">
              <span class="text">财务费用</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version1.financeTotal}}万元</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version2.financeTotal}}万元</span>
            </div>
            <div class="cell" :class="getClass('financeTotal')">
              <span class="text">{{mainIndexData.diff.financeTotal}}%</span>
            </div>
          </swiper-slide>
          <swiper-slide class="row">
            <div class="cell">
              <span class="text">经营税金</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version1.operateVatAmount}}万元</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version2.operateVatAmount}}万元</span>
            </div>
            <div class="cell" :class="getClass('operateVatAmount')">
              <span class="text">{{mainIndexData.diff.operateVatAmount}}%</span>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="table" v-else-if="currentTabId === 5">
        <div class="row title">
          <div class="cell">
            <span class="text">成本单方</span>
          </div>
          <div class="separator-vertical" />
          <div class="cell">
            <span class="text">{{version1.name}}</span>
          </div>
          <div class="separator-vertical" />
          <div class="cell">
            <span class="text">{{version2.name}}</span>
          </div>
          <div class="separator-vertical" />
          <div class="cell">
            <span class="text">B-A</span>
          </div>
        </div>
        <swiper class="body" :modules="modules" direction="vertical" :autoplay="{delay: 5000, disableOnInteraction: false}" :slides-per-view="10">
          <swiper-slide class="row">
            <div class="cell">
              <span class="text">土地计容单方</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version1.landVolumnUnitPrice}}元/m²</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version2.landVolumnUnitPrice}}元/m²</span>
            </div>
            <div class="cell" :class="getClass('landVolumnUnitPrice')">
              <span class="text">{{mainIndexData.diff.landVolumnUnitPrice}}</span>
            </div>
          </swiper-slide>
          <swiper-slide class="row odd">
            <div class="cell">
              <span class="text">建安建筑单方</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version1.buildingUnitPrice}}元/m²</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version2.buildingUnitPrice}}元/m²</span>
            </div>
            <div class="cell" :class="getClass('buildingUnitPrice')">
              <span class="text">{{mainIndexData.diff.buildingUnitPrice}}</span>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="table" v-else-if="currentTabId === 6">
        <div class="row title">
          <div class="cell">
            <span class="text">应收应付</span>
          </div>
          <div class="separator-vertical" />
          <div class="cell">
            <span class="text">{{version1.name}}</span>
          </div>
          <div class="separator-vertical" />
          <div class="cell">
            <span class="text">{{version2.name}}</span>
          </div>
          <div class="separator-vertical" />
          <div class="cell">
            <span class="text">B-A</span>
          </div>
        </div>
        <swiper class="body" :modules="modules" direction="vertical" :autoplay="{delay: 5000, disableOnInteraction: false}" :slides-per-view="10">
          <swiper-slide class="row">
            <div class="cell">
              <span class="text">楼款应收未收</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version1.buildingPaymentReceivable}}万元</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version2.buildingPaymentReceivable}}万元</span>
            </div>
            <div class="cell" :class="getClass('buildingPaymentReceivable')">
              <span class="text">{{mainIndexData.diff.buildingPaymentReceivable}}</span>
            </div>
          </swiper-slide>
          <swiper-slide class="row odd">
            <div class="cell">
              <span class="text">总投应付未付</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version1.investPaidTotal}}万元</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version2.investPaidTotal}}万元</span>
            </div>
            <div class="cell" :class="getClass('investPaidTotal')">
              <span class="text">{{mainIndexData.diff.investPaidTotal}}</span>
            </div>
          </swiper-slide>
          <swiper-slide class="row">
            <div class="cell">
              <span class="text">贷款本金余额</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version1.paymentPrincipalBalance}}万元</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version2.paymentPrincipalBalance}}万元</span>
            </div>
            <div class="cell" :class="getClass('paymentPrincipalBalance')">
              <span class="text">{{mainIndexData.diff.paymentPrincipalBalance}}</span>
            </div>
          </swiper-slide>
          <swiper-slide class="row odd">
            <div class="cell">
              <span class="text">往来（负数应收）</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version1.negativeTransactionReceivable}}万元</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version2.negativeTransactionReceivable}}万元</span>
            </div>
            <div class="cell" :class="getClass('negativeTransactionReceivable')">
              <span class="text">{{mainIndexData.diff.negativeTransactionReceivable}}</span>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="table" v-else-if="currentTabId === 7">
        <div class="row title">
          <div class="cell">
            <span class="text">权责利润</span>
          </div>
          <div class="separator-vertical" />
          <div class="cell">
            <span class="text">{{version1.name}}</span>
          </div>
          <div class="separator-vertical" />
          <div class="cell">
            <span class="text">{{version2.name}}</span>
          </div>
          <div class="separator-vertical" />
          <div class="cell">
            <span class="text">B-A</span>
          </div>
        </div>
        <swiper class="body" :modules="modules" direction="vertical" :autoplay="{delay: 5000, disableOnInteraction: false}" :slides-per-view="10">
          <swiper-slide class="row">
            <div class="cell">
              <span class="text">权责收入</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version1.powerIncome}}万元</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version2.powerIncome}}万元</span>
            </div>
            <div class="cell" :class="getClass('powerIncome')">
              <span class="text">{{mainIndexData.diff.powerIncome}}</span>
            </div>
          </swiper-slide>
          <swiper-slide class="row odd">
            <div class="cell">
              <span class="text">权责支出</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version1.powerPaid}}万元</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version2.powerPaid}}万元</span>
            </div>
            <div class="cell" :class="getClass('powerPaid')">
              <span class="text">{{mainIndexData.diff.powerPaid}}</span>
            </div>
          </swiper-slide>
          <swiper-slide class="row">
            <div class="cell">
              <span class="text">权责利润</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version1.powerProfit}}万元</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version2.powerProfit}}万元</span>
            </div>
            <div class="cell" :class="getClass('powerProfit')">
              <span class="text">{{mainIndexData.diff.powerProfit}}</span>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="table" v-else-if="currentTabId === 8">
        <div class="row title">
          <div class="cell">
            <span class="text">重要节点</span>
          </div>
          <div class="separator-vertical" />
          <div class="cell">
            <span class="text">{{version1.name}}</span>
          </div>
          <div class="separator-vertical" />
          <div class="cell">
            <span class="text">{{version2.name}}</span>
          </div>
          <div class="separator-vertical" />
          <div class="cell">
            <span class="text">逾期天数</span>
          </div>
        </div>
        <swiper class="body" :modules="modules" direction="vertical" :autoplay="{delay: 5000, disableOnInteraction: false}" :slides-per-view="10">
          <swiper-slide class="row">
            <div class="cell">
              <span class="text">土地获取</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version1.landAcquisition}}</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version2.landAcquisition}}</span>
            </div>
            <div class="cell" :class="getSignificantCodeClass('landAcquisitionDiff')">
              <span class="text">{{mainIndexData.diff.landAcquisitionDiff}}</span>
            </div>
          </swiper-slide>
          <swiper-slide class="row odd">
            <div class="cell">
              <span class="text">用地证</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version1.landUseCertificate}}</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version2.landUseCertificate}}</span>
            </div>
            <div class="cell" :class="getSignificantCodeClass('landUseCertificateDiff')">
              <span class="text">{{mainIndexData.diff.landUseCertificateDiff}}</span>
            </div>
          </swiper-slide>
          <swiper-slide class="row">
            <div class="cell">
              <span class="text">国土证</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version1.nationalLandCertificate}}</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version2.nationalLandCertificate}}</span>
            </div>
            <div class="cell" :class="getSignificantCodeClass('nationalLandCertificateDiff')">
              <span class="text">{{mainIndexData.diff.nationalLandCertificateDiff}}</span>
            </div>
          </swiper-slide>
          <swiper-slide class="row odd">
            <div class="cell">
              <span class="text">工规证</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version1.workRegulationCertificate}}</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version2.workRegulationCertificate}}</span>
            </div>
            <div class="cell" :class="getSignificantCodeClass('workRegulationCertificateDiff')">
              <span class="text">{{mainIndexData.diff.workRegulationCertificateDiff}}</span>
            </div>
          </swiper-slide>
          <swiper-slide class="row">
            <div class="cell">
              <span class="text">施工证</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version1.constructionPermit}}</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version2.constructionPermit}}</span>
            </div>
            <div class="cell" :class="getSignificantCodeClass('constructionPermitDiff')">
              <span class="text">{{mainIndexData.diff.constructionPermitDiff}}</span>
            </div>
          </swiper-slide>
          <swiper-slide class="row odd">
            <div class="cell">
              <span class="text">开工</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version1.beginConstruction}}</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version2.beginConstruction}}</span>
            </div>
            <div class="cell" :class="getSignificantCodeClass('beginConstructionDiff')">
              <span class="text">{{mainIndexData.diff.beginConstructionDiff}}</span>
            </div>
          </swiper-slide>
          <swiper-slide class="row">
            <div class="cell">
              <span class="text">结构±0</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version1.structureCapping}}</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version2.structureCapping}}</span>
            </div>
            <div class="cell" :class="getSignificantCodeClass('structureCappingDiff')">
              <span class="text">{{mainIndexData.diff.structureCappingDiff}}</span>
            </div>
          </swiper-slide>
          <swiper-slide class="row odd">
            <div class="cell">
              <span class="text">预售证</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version1.preSaleCertificate}}</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version2.preSaleCertificate}}</span>
            </div>
            <div class="cell" :class="getSignificantCodeClass('preSaleCertificateDiff')">
              <span class="text">{{mainIndexData.diff.preSaleCertificateDiff}}</span>
            </div>
          </swiper-slide>
          <swiper-slide class="row">
            <div class="cell">
              <span class="text">开盘</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version1.realEstateOpening}}</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version2.realEstateOpening}}</span>
            </div>
            <div class="cell" :class="getSignificantCodeClass('realEstateOpeningDiff')">
              <span class="text">{{mainIndexData.diff.realEstateOpeningDiff}}</span>
            </div>
          </swiper-slide>
          <swiper-slide class="row odd">
            <div class="cell">
              <span class="text">封顶</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version1.structureCapping}}</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version2.structureCapping}}</span>
            </div>
            <div class="cell" :class="getSignificantCodeClass('structureCappingDiff')">
              <span class="text">{{mainIndexData.diff.structureCappingDiff}}</span>
            </div>
          </swiper-slide>
          <swiper-slide class="row">
            <div class="cell">
              <span class="text">竣备</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version1.completionRecord}}</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version2.completionRecord}}</span>
            </div>
            <div class="cell" :class="getSignificantCodeClass('completionRecordDiff')">
              <span class="text">{{mainIndexData.diff.completionRecordDiff}}</span>
            </div>
          </swiper-slide>
          <swiper-slide class="row odd">
            <div class="cell">
              <span class="text">交楼</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version1.deliveryOwner}}</span>
            </div>
            <div class="cell">
              <span class="text">{{mainIndexData.version2.deliveryOwner}}</span>
            </div>
            <div class="cell" :class="getSignificantCodeClass('deliveryOwnerDiff')">
              <span class="text">{{mainIndexData.diff.deliveryOwnerDiff}}</span>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </template>
    <empty v-else />
	</div>
</template>

<script>
import { reactive, ref, computed, toRefs, onBeforeUnmount }  from "vue";
import { useStore } from "vuex";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import Empty from "@/components/common/Empty";
import project from "@/compositions/project";

export default {
  name: "ProjectTable",
  components: {
    Swiper,
    SwiperSlide,
    Empty
  },
  setup() {
    const store = useStore();
    const rightIcon = computed(() => store.getters["theme/rightIcon"]);
    const state = reactive({
      tabs: [
        { id: 1, name: "核心指标" },
        { id: 2, name: "经济指标" },
        { id: 3, name: "销售指标" },
        { id: 4, name: "总投指标" },
        { id: 5, name: "成本单方" },
        { id: 6, name: "应收应付" },
        { id: 7, name: "权责利润" },
        { id: 8, name: "重要节点" }
      ],
      currentTabId: 1
    });
    const swiper = ref(null);

    const data = project.init();
    const { version1, version2, mainIndexData } = data;

    let timer = null;

    const startTimer = () => {
      clearTimer();
      timer = setInterval(updateTimer, 10000);
    };

    const clearTimer = () => {
      if (timer) {
        clearInterval(timer);
        timer = null;
      }
    };

    const updateTimer = () => {
      if (state.currentTabId === 8) {
        state.currentTabId = 1;
      } else {
        state.currentTabId++;
      }
      const index = state.currentTabId - 1;
      swiper.value.$el.swiper.slideTo(index);
    };

    const onTabClick = (tab) => {
      state.currentTabId = tab.id;
      startTimer();
    };

    const onPrevClick = () => {
      if (state.currentTabId === 1) {
        return;
      }
      state.currentTabId -= 1;
      const index = state.currentTabId - 1;
      swiper.value.$el.swiper.slideTo(index);
      startTimer();
    };

    const onNextClick = () => {
      if (state.currentTabId === 8) {
        return;
      }
      state.currentTabId += 1;
      const index = state.currentTabId - 1;
      swiper.value.$el.swiper.slideTo(index);
      startTimer();
    };

    const getClass = (field) => {
      const value = mainIndexData.value.diff[field];
      return { red: value < 0, green: value > 0 };
    }

    const getSignificantCodeClass = (field) => {
      const value = mainIndexData.value.diff[field];
      return { green: value < 0, red: value > 0 };
    };

    const formatIrr = (irr) => {
      return irr > 0 ? (irr * 100).toFixed(2) + '%' : '负值'
    };

    const formatPercentage = (p) => {
      return (p * 100).toFixed(2) + '%'
    };

    onBeforeUnmount(() => {
      clearTimer();
    });

    startTimer();

    return {
      rightIcon,
      ...toRefs(state),
      swiper,
      version1,
      version2,
      mainIndexData,
      modules: [Autoplay],
      onTabClick,
      onPrevClick,
      onNextClick,
      getClass,
      getSignificantCodeClass,
      formatIrr,
      formatPercentage
    };
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/main.scss";

.project-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.tabs {
  position: relative;
  width: 100%;
  height: 36px;

  .tab {
    position: relative;
    @extend %center;
    width: 80px;
    height: 36px;
    cursor: pointer;

    .text {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: var(--secondary-font-color);
    }

    &.current {
      background: linear-gradient(180deg, rgba(255, 133, 23, 0) 0%, rgba(255, 133, 23, 0.2) 100%);

      .text {
        color: var(--brand-color);
      }
    }

    .line {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: var(--brand-color);
    }
  }

  .btn-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 6px;
    display: flex;
    margin: auto;
    width: 24px;
    height: 24px;
    z-index: 2;
  }

  .btn-next {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 6px;
    display: flex;
    margin: auto;
    width: 24px;
    height: 24px;
    z-index: 2;
  }

  .right-icon {
    width: 100%;
    height: 100%;

    &.left {
      transform: scaleX(-1);
    }
  }
}

.table {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.body {
  width: 100%;
  height: 376px;
}

.row {
  @extend %horizontal;
  width: 100%;
  height: 37.6px;

  .cell {
    @extend %horizontal;
    flex: 1;
    height: 100%;

    .text {
      margin-left: 12px;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: var(--main-font-color);
    }

    &.red {
      .text {
        color: var(--danger-color);
      }
    }

    &.green {
      .text {
        color: var(--success-color);
      }
    }
  }

  &.title {
    height: 36px;
    background-color: var(--secondary-background-color);

    .text {
      color: var(--tip-font-color);
    }
  }

  &.odd {
    background-color: var(--thirdary-background-color);
  }

  .separator-vertical {
    width: 1px;
    height: 19px;
    background-color: var(--secondary-border-color);
  }
}
</style>
